import React, { useEffect, useState } from "react";
import useTranslation from "components/customHooks/translations";
import {
    Box,
    Button,
    Divider,
    IconButton,
    Paper,
    Popover,
    Stack,
    Tab,
    Tabs,
    Typography,
} from "@mui/material";
import { IShippingMethod } from "interfaces";
import { useUserContext } from "components/contexts/UserContext";
import { useLoadingContext } from "components/contexts/LoadingContext";
import { getShippingMethodsByTenant } from "services/tenants";
import ShippingMethodForm from "./ShippingMethodForm";
import {
    Add,
    Dangerous,
    EditOutlined,
    Warning,
    WarningAmber,
} from "@mui/icons-material";

const ShippingMethods = () => {
    const { selectedTenant, tenants } = useUserContext();
    const { setLoading } = useLoadingContext();
    const [shippingMethods, setShippingMethods] = useState<IShippingMethod[]>(
        []
    );

    const [showNewShippingMethod, setShowNewShippingMethod] = useState(false);
    const [shippingMethodToEdit, setShippingMethodToEdit] =
        useState<IShippingMethod | null>(null);
    const [updateTrigger, setUpdateTrigger] = useState(false);
    const t = useTranslation();

    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    useEffect(() => {
        setLoading(true);

        if (tenants !== null) {
            getShippingMethodsByTenant(tenants[selectedTenant].id)
                .then((res) => {
                    setShippingMethods(res.data["hydra:member"]);
                    console.log(res.data["hydra:member"]);
                    setLoading(false);
                })
                .catch((e) => console.log(e));
        }
    }, [selectedTenant, tenants, updateTrigger]);

    return (
        <Stack>
            <Box
                sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                }}
            >
                <Button
                    variant="contained"
                    startIcon={<Add />}
                    onClick={() => setShowNewShippingMethod(true)}
                >
                    {t.shipping.newShippingMethodButton}
                </Button>
            </Box>
            {shippingMethods.map((shippingMethod) => (
                <Box key={shippingMethod.id}>
                    <Stack direction="row" spacing={2} alignItems={"center"}>
                        <IconButton
                            onClick={() => {
                                setShippingMethodToEdit(shippingMethod);
                                setShowNewShippingMethod(true);
                            }}
                        >
                            <EditOutlined />
                        </IconButton>
                        <Stack>
                            <Typography variant="h5">
                                {shippingMethod.name}
                            </Typography>
                            {shippingMethod.type === "courier" && (
                                <Stack direction={"row"} spacing={1}>
                                    <Typography variant="body2">
                                        {t.shipping.courierLabel}:
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        color={"primary"}
                                    >
                                        {shippingMethod.name}
                                    </Typography>
                                </Stack>
                            )}
                        </Stack>
                        <Box>
                            <Typography sx={{ ml: 4 }}>
                                {(tenants[selectedTenant].settings.taxIncluded
                                    ? shippingMethod.netPrice
                                    : shippingMethod.grossPrice) +
                                    " " +
                                    shippingMethod.priceCurrency}
                            </Typography>
                        </Box>
                    </Stack>
                    <Divider sx={{ my: 2 }} />
                </Box>
            ))}
            {shippingMethods.length === 0 && (
                <Typography>{t.shipping.noShippingMethods}</Typography>
            )}
            <ShippingMethodForm
                showNewShippingMethod={showNewShippingMethod}
                setShowNewShippingMethod={setShowNewShippingMethod}
                shippingMethodToEdit={shippingMethodToEdit}
                setShippingMethodToEdit={setShippingMethodToEdit}
                updateTrigger={updateTrigger}
                setUpdateTrigger={setUpdateTrigger}
            ></ShippingMethodForm>
            <Popover
                id="mouse-over-popover"
                sx={{
                    pointerEvents: "none",
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <Typography
                    sx={{
                        p: 1,
                        color: "text.secondary",
                        whiteSpace: "pre-line",
                    }}
                >
                    {t.shipping.finishSetUp}
                </Typography>
            </Popover>
        </Stack>
    );
};

export default ShippingMethods;
