import * as React from "react";
import { useLocation } from "react-router-dom";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import useTranslation from "components/customHooks/translations";

const Customers = () => {
    const { pathname } = useLocation();
    const translation = useTranslation();

    return (
        <Box>
            <Typography variant="h4">{translation.customersTitle}</Typography>
        </Box>
    );
};

export default Customers;
