import React, { useEffect, useState } from "react";
import { useUserContext } from "components/contexts/UserContext";
import useTranslation from "components/customHooks/translations";
import { Formik } from "formik";
import {
    createIntegrations,
    createSettings,
    createTax,
    getIntegrationsByTenant,
    getSettingsByTenant,
    getTaxesByTenant,
    updateIntegrations,
    updateSettings,
} from "services/tenants";

import PapperCollapse from "components/PaperCollapse";
import { useLoadingContext } from "components/contexts/LoadingContext";
import { Box, Stack } from "@mui/material";
import { useSnackbarContext } from "components/contexts/SnackbarContext";
import { IAppIntegrations, ISettings, ITax } from "interfaces";
import SwitchField from "components/Form/SwitchField/SwitchField";
import ArrayNumberPercentageField from "components/Form/ArrayNumberPercentageField";
import { LoadingButton } from "@mui/lab";
import { errorMessage } from "helpers";
import FormTextField from "components/Form/FormTextField/FormTextField";

const Integrations = () => {
    const { selectedTenant, tenants, updateTrigger, setUpdateTrigger } =
        useUserContext();
    const { setLoading } = useLoadingContext();
    const { setSuccessMessage, setErrorMessage } = useSnackbarContext();
    const [integrations, setIntegrations] = useState<IAppIntegrations>({});
    const [localUpdateTrigger, setLocalUpdateTrigger] = useState(false);
    const translation = useTranslation();

    useEffect(() => {
        setLoading(true);
        if (tenants !== null) {
            getIntegrationsByTenant(tenants[selectedTenant].id)
                .then((res) => {
                    if (res.data["hydra:member"].length === 0) {
                        createIntegrations({
                            tenant: "tenants/" + tenants[selectedTenant].id,
                        }).then(() => {
                            setUpdateTrigger(!updateTrigger);
                        });
                    } else {
                        setIntegrations(res.data["hydra:member"][0]);

                        setLoading(false);
                    }
                })
                .catch((e) => console.log(e));
        }
    }, [selectedTenant, tenants, updateTrigger, localUpdateTrigger]);

    const submitForm = async (values: any, actions: any) => {
        try {
            await updateIntegrations({
                id: integrations.id,
                gtm: values.gtm,
                pixel: values.pixel,
                mailChimp: values.mailChimp,
            });
        } catch (e) {
            actions.setSubmitting(false);
            setErrorMessage(errorMessage(e));
            return;
        }

        setLocalUpdateTrigger(!localUpdateTrigger);

        setSuccessMessage(translation.savedMessage);
    };

    return (
        <>
            <PapperCollapse>
                <Formik
                    initialValues={{
                        gtm: integrations.gtm ? integrations.gtm : "",
                        pixel: integrations.pixel ? integrations.pixel : "",
                        mailChimp: integrations.mailChimp
                            ? integrations.mailChimp
                            : "",
                    }}
                    enableReinitialize
                    onSubmit={(values, actions) => {
                        actions.setSubmitting(true);
                        submitForm(values, actions);
                    }}
                >
                    {({ isSubmitting, submitForm, values, resetForm }) => (
                        <Box sx={{ height: "100%" }}>
                            <Stack spacing={2}>
                                <Box>
                                    <FormTextField
                                        name="gtm"
                                        label={
                                            translation.integrations.gtmLabel
                                        }
                                    ></FormTextField>
                                </Box>
                                <Box>
                                    <FormTextField
                                        name="pixel"
                                        label={
                                            translation.integrations.pixelLabel
                                        }
                                    ></FormTextField>
                                </Box>
                                <Box>
                                    <FormTextField
                                        name="mailChimp"
                                        label={
                                            translation.integrations
                                                .mailchimpLabel
                                        }
                                    ></FormTextField>
                                </Box>

                                <Box
                                    display={"flex"}
                                    justifyContent="flex-start"
                                >
                                    <LoadingButton
                                        type="submit"
                                        onClick={submitForm}
                                        variant="contained"
                                        loading={isSubmitting}
                                    >
                                        {translation.save}
                                    </LoadingButton>
                                </Box>
                            </Stack>
                        </Box>
                    )}
                </Formik>
            </PapperCollapse>
        </>
    );
};

export default Integrations;
