import * as React from "react";
import { InfoOutlined } from "@mui/icons-material";
import {
    Alert,
    IconButton,
    Popover,
    Snackbar,
    Typography,
} from "@mui/material";
import { useSnackbarContext } from "components/contexts/SnackbarContext";

const SnackbarSuccess = ({ successMessage }: { successMessage: string }) => {
    const [open, setOpen] = React.useState(false);
    const { setSuccessMessage } = useSnackbarContext();
    React.useEffect(() => {
        setOpen(Boolean(successMessage));
    }, [successMessage]);

    const handleClose = () => {
        setOpen(false);
        setSuccessMessage("");
    };

    return (
        <>
            <Snackbar
                open={open}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                autoHideDuration={2000}
                onClose={handleClose}
            >
                <Alert
                    variant="filled"
                    elevation={6}
                    onClose={handleClose}
                    severity="success"
                    sx={{ width: "100%" }}
                >
                    Success: {successMessage}
                </Alert>
            </Snackbar>
        </>
    );
};

export default SnackbarSuccess;
