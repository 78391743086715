import { LoadingButton } from "@mui/lab";
import { Box, Button, LinearProgress, Paper, Typography } from "@mui/material";
import { useSnackbarContext } from "components/contexts/SnackbarContext";
import { useUserContext } from "components/contexts/UserContext";
import useTranslation from "components/customHooks/translations";
import DropZoneBulkProductImg from "components/Form/DropZoneBulkProductImg";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { IBulkUploadedProduct, IImageObject } from "interfaces";
import { useState } from "react";
import { editProduct } from "services/products";
import { useNavigate } from "react-router-dom";
import { routeNames } from "routes";

const BulkProductsImages = ({
  bulkUploadedProducts,
  setBulkUploadedProducts,
}: {
  bulkUploadedProducts: IBulkUploadedProduct[];
  setBulkUploadedProducts: any;
}) => {
  const { selectedTenant, tenants } = useUserContext();
  const [openUploading, setOpenUploading] = useState(false);
  const [uploadedProductImagesCount, setUploadedProductImagesCount] =
    useState(0);
  const translation = useTranslation();
  const navigate = useNavigate();
  const { setSuccessMessage, setErrorMessage } = useSnackbarContext();

  const setNewUploadedProductImages = (
    images: IImageObject[],
    index: number
  ) => {
    const newProduct = JSON.parse(JSON.stringify(bulkUploadedProducts[index]));
    newProduct.images = JSON.parse(JSON.stringify(images));
    const newBulkUploadedProducts = JSON.parse(
      JSON.stringify(bulkUploadedProducts)
    );
    newBulkUploadedProducts[index] = newProduct;
    setBulkUploadedProducts(newBulkUploadedProducts);
  };

  const handleUploadImages = async () => {
    console.log(bulkUploadedProducts);
    setOpenUploading(true);
    for (let i = 0; i < bulkUploadedProducts.length; i++) {
      try {
        const response = await editProduct(bulkUploadedProducts[i]);
        console.log(response);
        setUploadedProductImagesCount((value) => value + 1);
      } catch (e) {
        console.log(e);
        setUploadedProductImagesCount((value) => value + 1);
      }
    }
  };

  const handleCloseUploading = () => {
    setOpenUploading(false);
    navigate(routeNames.products);
  };

  return (
    <>
      <Typography variant="h3">
        {translation.productsExportImport.bulkUploadImages}
      </Typography>
      <Typography variant="body1" sx={{ my: 2 }}>
        {bulkUploadedProducts.length +
          " " +
          translation.productsExportImport.productsImported}
      </Typography>
      {bulkUploadedProducts &&
        bulkUploadedProducts.map((uploadedProduct, index) => (
          <Paper
            key={"uploaded-product-" + uploadedProduct.id}
            sx={{ p: 2, my: 2 }}
          >
            <Typography variant="body2" sx={{ mb: 2 }}>
              {uploadedProduct.name + " - " + uploadedProduct.sku}
            </Typography>
            <DropZoneBulkProductImg
              label={translation.products.imagesField}
              labelDescription={translation.products.imagesDescriptionField}
              uploadedProduct={uploadedProduct}
              setNewUploadedProductImages={setNewUploadedProductImages}
              productIndex={index}
            ></DropZoneBulkProductImg>
          </Paper>
        ))}
      <LoadingButton variant={"contained"} onClick={() => handleUploadImages()}>
        {translation.productsExportImport.uploadImages}
      </LoadingButton>
      <Dialog
        open={openUploading}
        onClose={() => {
          return;
        }}
        fullWidth={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {translation.productsExportImport.uploadingImages}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {uploadedProductImagesCount !== bulkUploadedProducts.length
              ? translation.productsExportImport.doNotClose
              : translation.productsExportImport.done}
          </DialogContentText>
          <DialogContentText id="alert-dialog-description">
            {uploadedProductImagesCount +
              "/" +
              bulkUploadedProducts.length +
              translation.productsExportImport.uploaded}
          </DialogContentText>
          <Box sx={{ width: "100%", my: 2 }}>
            <LinearProgress
              variant="determinate"
              value={Math.round(
                (100 * uploadedProductImagesCount) / bulkUploadedProducts.length
              )}
            />
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center" }}>
          {uploadedProductImagesCount === bulkUploadedProducts.length && (
            <Button variant="outlined" onClick={handleCloseUploading}>
              {translation.productsExportImport.closeAndGoToProducts}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BulkProductsImages;
