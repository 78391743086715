import React, { useState } from "react";
import { useField } from "formik";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { companyData } from "helpers/dynamicData";
import useTranslation from "components/customHooks/translations";
import { getPolicyTemplates } from "services/tenants";

const QuillSnippetField = ({ name }: { name: string }) => {
  const [quillRef, setQuillRef] = useState<ReactQuill | null>(null);
  const [templates, setTemplates] = useState<any>(null);
  const [cursorPosition, setCursorPosition] = useState(0);
  const [searchFilter, setSearchFilter] = useState("");
  const [openTemplates, setOpenTemplates] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [field, meta, helper] = useField(name);
  const isValid = !meta.error;
  const isInvalid = meta.touched && !isValid;
  const translation = useTranslation();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setSearchFilter("");
    if (quillRef === null) return;
    const quillEditor = quillRef.getEditor();
    const range = quillEditor.getSelection();
    const position = range ? range.index : 0;
    setCursorPosition(position);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenTemplates = () => {
    getPolicyTemplates().then((res) => {
      setTemplates(res.data);
    });
    setOpenTemplates(true);
  };
  const handleCloseTemplates = () => {
    setOpenTemplates(false);
  };

  const open = Boolean(anchorEl);

  const handleInsert = (text: string) => {
    handleClose();
    if (quillRef === null) return;
    if (typeof quillRef.getEditor !== "function") return;
    const quillEditor = quillRef.getEditor();
    const range = quillEditor.getSelection();
    let position = range ? range.index : 0;
    const textToInsert = "{{" + text + "}}";
    console.log(cursorPosition);
    if (cursorPosition !== 0) {
      position = cursorPosition;
    }
    console.log(position);
    quillEditor.insertText(position, textToInsert);
    quillEditor.focus();
    quillEditor.setSelection(position + textToInsert.length, 0);
    setCursorPosition(0);
  };

  const handleListItemClick = (template: any) => {
    helper.setValue(template.content);
    handleCloseTemplates();
  };

  return (
    <>
      <Box display="flex" justifyContent={"space-between"}>
        <Button variant="outlined" onClick={handleOpenTemplates}>
          {translation.policies.generateFromTemplate}
        </Button>
        <Button variant="contained" onClick={handleClick}>
          {translation.policies.dynamicData}
        </Button>
      </Box>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        autoFocus={false}
        onKeyDown={(e) => e.stopPropagation()}
        disableAutoFocusItem
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          disableRipple
          sx={{ p: 2 }}
          onKeyDown={(e) => e.stopPropagation()}
        >
          <TextField
            variant="standard"
            label={translation.policies.searchData}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setSearchFilter(event.target.value);
            }}
          ></TextField>
        </MenuItem>
        {companyData
          .filter((data: any) =>
            searchFilter === ""
              ? true
              : data.label.toLowerCase().includes(searchFilter.toLowerCase())
          )
          .map((field, index) => {
            return (
              <MenuItem
                key={"menu-snipped-" + index}
                onClick={() => {
                  handleInsert(field.field);
                  handleClose();
                }}
              >
                {field.label}
              </MenuItem>
            );
          })}
      </Menu>

      <ReactQuill
        ref={(el) => setQuillRef(el)}
        id={field.name}
        onChange={(value) => {
          helper.setValue(value);
        }}
        value={field.value}
        theme="snow"
        bounds={`.ql-container`}
        modules={{
          toolbar: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ size: [] }],
            ["bold", "italic", "underline", "strike"],
            ["link", "blockquote", "code"],
            [
              {
                list: "ordered",
              },
              {
                list: "bullet",
              },
              { align: [] },
            ],
            ["image", "video"],
          ],
        }}
      />
      <Dialog onClose={handleCloseTemplates} open={openTemplates}>
        <DialogTitle>{translation.policies.generateFromTemplate}</DialogTitle>
        <DialogContent>
          <Typography fontSize={12} color={"warning.main"}>
            {translation.possibleDataLossWarning}
          </Typography>
          <List sx={{ pt: 0 }}>
            {templates &&
              templates.map((template: any) => (
                <ListItem key={template.url} disableGutters>
                  <ListItemButton onClick={() => handleListItemClick(template)}>
                    <ListItemText primary={template.title} />
                  </ListItemButton>
                </ListItem>
              ))}
          </List>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default QuillSnippetField;
