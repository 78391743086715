import * as React from "react";
import { useLocation } from "react-router-dom";
import { Box, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import useTranslation from "components/customHooks/translations";
import LoadingIcon from "components/Feedback/LoadingIcon";
import PageHeader from "components/PageHeader";
import { useUserContext } from "components/contexts/UserContext";
import Payments from "pages/Payments";
import Application from "pages/Application";
import Policies from "pages/Policies";
import Taxes from "pages/Taxes";
import Currency from "pages/Currency";

const SalesChannels = () => {
  const { tenantsIsLoading } = useUserContext();

  const { pathname } = useLocation();
  const translation = useTranslation();

  return (
    <>
      {tenantsIsLoading ? (
        <LoadingIcon />
      ) : (
        <>
          <Stack spacing={2}>
            <Application></Application>
            <Payments></Payments>
            <Policies></Policies>
            <Taxes></Taxes>
            <Currency />
          </Stack>
        </>
      )}
    </>
  );
};

export default SalesChannels;
