import {
    AddCircleOutlined,
    ArrowDropDownOutlined,
    ArrowDropUpOutlined,
} from "@mui/icons-material";
import {
    Box,
    Button,
    Collapse,
    IconButton,
    Paper,
    Stack,
    Typography,
} from "@mui/material";
import useTranslation from "components/customHooks/translations";
import React, { useState } from "react";

const PapperCollapse = ({
    title = "",
    createFunction,
    children,
}: {
    title?: string;
    createFunction?: any;
    children?: React.ReactNode;
}) => {
    const translation = useTranslation();

    return (
        <Paper>
            <Stack direction="row" alignItems={"center"}>
                <Box flexGrow={1} sx={{ px: 2, py: 3 }}>
                    <Typography variant="h5">{title}</Typography>
                </Box>
                <Stack direction="row" spacing={1}>
                    {createFunction && (
                        <Box sx={{ m: 2 }}>
                            <Button
                                variant="contained"
                                onClick={createFunction}
                                startIcon={<AddCircleOutlined />}
                            >
                                {translation.addNew}
                            </Button>
                        </Box>
                    )}
                </Stack>
            </Stack>
            <Box sx={{ px: 2, pb: 2 }}> {children}</Box>
        </Paper>
    );
};

export default PapperCollapse;
