import { useMemo, useCallback } from "react";
import { useField } from "formik";
import { useDropzone, FileWithPath } from "react-dropzone";
import IconButton from "@mui/material/IconButton";
import { relative } from "path";
import {} from "react-beautiful-dnd";
import { Box, Stack, Typography } from "@mui/material";
import { AddCircleOutlined } from "@mui/icons-material";
import useTranslation from "components/customHooks/translations";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  borderWidth: 2,
  borderRadius: 8,
  padding: "16px 16px",
  borderColor: "#BEBEBE",
  borderStyle: "dashed",
  backgroundColor: "#FFF",
  color: "#000",
  fontSize: "14px",
  fontWeight: 500,
  outline: "none",
  transition: "border .24s ease-in-out",
  height: "100%",
  width: "100%",
  marginBottom: "20px",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const DropZoneImportField = ({
  label,
  labelDescription,
  name,
}: {
  label?: string;
  labelDescription?: string;
  name: string;
}) => {
  const [field, meta, helper] = useField(name);
  const translation = useTranslation();

  const onDrop = useCallback(
    (acceptedFiles: any) => {
      acceptedFiles.forEach((file: File) => {
        helper.setValue(file);
        // const reader = new FileReader();
        // reader.readAsDataURL(file);
        // reader.onload = function () {
        //   newFile = reader.result;
        //   helper.setValue(newFile);
        // };
      });
    },
    [field.value, helper]
  );

  const {
    acceptedFiles,
    open,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept: { "text/csv": [".csv"] },
    multiple: false,
  });

  const style: any = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragAccept, isDragReject]
  );
  console.log(field.value);
  const isValid = !meta.error;
  const isInvalid = meta.touched && !isValid;
  return (
    <>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <div {...getRootProps({ style })}>
          <Stack
            direction={"row"}
            spacing={2}
            justifyContent="flex-start"
            alignItems="center"
          >
            <Box>
              <IconButton color="primary" sx={{ p: 2 }}>
                <AddCircleOutlined
                  sx={{ fontSize: "40px" }}
                ></AddCircleOutlined>
              </IconButton>
            </Box>
            <input {...getInputProps()} />
            <Stack spacing={1}>
              <input {...getInputProps()} />
              <Typography>{label}</Typography>

              <Typography
                sx={{
                  color: "text.secondary",
                }}
              >
                {!field.value
                  ? labelDescription
                  : translation.productsExportImport.fileSelected +
                    " - " +
                    field.value.name}
              </Typography>
            </Stack>
          </Stack>
        </div>
      </Stack>
    </>
  );
};

export default DropZoneImportField;
