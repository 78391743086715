import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import LoadingIcon from "components/Feedback/LoadingIcon";
import { editOrderStatus, getOrder, createAwb } from "services/orders";
import {
    Grid,
    Box,
    Typography,
    Stack,
    Button,
    ToggleButton,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import useTranslation from "components/customHooks/translations";
import Paper from "@mui/material/Paper";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { orderStatuses } from "helpers/orderStatuses";
import PageHeader from "components/PageHeader";
import OrderProductsList from "components/Orders/OrderProductsList";
import { Done } from "@mui/icons-material";
import { useUserContext } from "components/contexts/UserContext";
import { useSnackbarContext } from "components/contexts/SnackbarContext";
import { errorMessage } from "helpers";

export async function loader({ params }: any) {
    return getOrder(params.orderId);
}

const OrderView = () => {
    const { selectedTenant, tenants } = useUserContext();
    const { setSuccessMessage, setErrorMessage } = useSnackbarContext();

    const [order, setOrder] = useState<any>(null);
    const [orderisLoading, setOrderisLoading] = useState(true);
    const [awb, setAwb] = useState<any>(null);
    const [orderStatus, setOrderStatus] = useState(undefined);
    const [successStatus, setSuccessStatus] = useState(false);
    const [loadingStatus, setLoadingStatus] = useState(false);
    const [loadingAwb, setLoadingAwb] = useState(false);
    const [awbError, setAwbError] = useState("");
    const [error, setError] = useState(false);
    const translation = useTranslation();
    const { orderId } = useParams();

    useEffect(() => {
        if (orderId !== undefined) {
            getOrder(orderId)
                .then((order) => {
                    if (order.data) {
                        console.log(order);
                        if (order.data.awbs.length > 0) {
                            setAwb(order.data.awbs[0]);
                        }
                        setOrder(order.data);
                        setOrderisLoading(false);
                    }
                })
                .catch((e) => console.log(e));
        }
    }, []);

    const navigate = useNavigate();

    const handleChangeStatus = (event: any) => {
        setOrderStatus(event.target.value);
    };

    const saveStatus = () => {
        if (orderStatus) {
            setLoadingStatus(true);
            editOrderStatus(orderId, orderStatus).then((res) => {
                if (res.status < 300) {
                    setLoadingStatus(false);
                    setSuccessStatus(true);
                    setTimeout(() => {
                        setSuccessStatus(false);
                    }, 6000);
                }
            });
        }
    };

    const generateAwb = () => {
        setLoadingAwb(true);
        createAwb(order["@id"], order.shippingMethod["@id"])
            .then((res) => {
                setLoadingAwb(false);
                if (res.status < 300) {
                    setAwb(res.data);
                }
            })
            .catch((e) => {
                setLoadingAwb(false);
                setErrorMessage(errorMessage(e));
            });
    };

    return (
        <>
            {orderisLoading || tenants == null ? (
                <LoadingIcon />
            ) : (
                <>
                    {order && (
                        <Box>
                            <PageHeader>
                                {translation.orderTitle + " #" + order.id}
                            </PageHeader>
                            <Stack spacing={2}>
                                <Paper sx={{ padding: 2 }}>
                                    <Stack spacing={2}>
                                        <Stack
                                            direction="row"
                                            justifyContent={"space-between"}
                                            alignItems={"center"}
                                        >
                                            <Stack
                                                direction="row"
                                                spacing={2}
                                                alignItems={"center"}
                                            >
                                                <FormControl
                                                    variant="standard"
                                                    sx={{
                                                        m: 1,
                                                        minWidth: 120,
                                                    }}
                                                >
                                                    <InputLabel id="status-select-standard-label">
                                                        {translation.status}
                                                    </InputLabel>
                                                    <Select
                                                        labelId="status-select-standard-label"
                                                        id="status-select-standard"
                                                        defaultValue={
                                                            order.status
                                                        }
                                                        onChange={
                                                            handleChangeStatus
                                                        }
                                                        label={
                                                            translation.status
                                                        }
                                                    >
                                                        {orderStatuses.map(
                                                            (orderStatus) => (
                                                                <MenuItem
                                                                    key={
                                                                        orderStatus.slug
                                                                    }
                                                                    value={
                                                                        orderStatus.slug
                                                                    }
                                                                >
                                                                    {
                                                                        orderStatus.label
                                                                    }
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    </Select>
                                                </FormControl>
                                                <Box>
                                                    <LoadingButton
                                                        onClick={() =>
                                                            saveStatus()
                                                        }
                                                        loading={loadingStatus}
                                                    >
                                                        {translation.save}
                                                    </LoadingButton>
                                                    {successStatus && (
                                                        <Done color="primary"></Done>
                                                    )}
                                                </Box>
                                            </Stack>
                                            <Box>
                                                <Stack
                                                    direction={"row"}
                                                    spacing={1}
                                                >
                                                    <Typography>
                                                        {
                                                            translation.order
                                                                .paymentMethod
                                                        }
                                                        :
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            fontWeight: 500,
                                                        }}
                                                        color={"primary.dark"}
                                                    >
                                                        {order.payment.label}
                                                    </Typography>
                                                </Stack>
                                            </Box>
                                        </Stack>
                                        <OrderProductsList order={order} />
                                        <Stack
                                            direction={"row"}
                                            justifyContent="flex-end"
                                            spacing={2}
                                            sx={{ paddingY: 4 }}
                                        >
                                            <Stack
                                                spacing={1}
                                                alignItems={"flex-end"}
                                            >
                                                <Stack
                                                    direction={"row"}
                                                    spacing={1}
                                                >
                                                    <Typography>
                                                        {
                                                            translation.order
                                                                .subtotal
                                                        }
                                                    </Typography>
                                                    <Box
                                                        sx={{ width: "100px" }}
                                                    >
                                                        <Typography align="right">
                                                            {tenants[
                                                                selectedTenant
                                                            ].settings
                                                                .taxIncluded
                                                                ? order
                                                                      .totalPrice
                                                                      .netPrice
                                                                : order
                                                                      .totalPrice
                                                                      .grossPrice}
                                                        </Typography>
                                                    </Box>
                                                    <Typography>
                                                        {
                                                            order.totalPrice
                                                                .priceCurrency
                                                        }
                                                    </Typography>
                                                </Stack>
                                                <Stack
                                                    direction={"row"}
                                                    spacing={1}
                                                >
                                                    <Typography>
                                                        {
                                                            translation.order
                                                                .shipping
                                                        }
                                                    </Typography>
                                                    <Box
                                                        sx={{ width: "100px" }}
                                                    >
                                                        <Typography align="right">
                                                            {tenants[
                                                                selectedTenant
                                                            ].settings
                                                                .taxIncluded
                                                                ? order
                                                                      .shippingMethodSnapshot
                                                                      .netPrice
                                                                : order
                                                                      .shippingMethodSnapshot
                                                                      .grossPrice}
                                                        </Typography>
                                                    </Box>
                                                    <Typography>
                                                        {
                                                            tenants[
                                                                selectedTenant
                                                            ].settings
                                                                .defaultCurrency
                                                        }
                                                    </Typography>
                                                </Stack>
                                                <Stack
                                                    direction={"row"}
                                                    spacing={1}
                                                >
                                                    <Typography>
                                                        {
                                                            translation.order
                                                                .totalDiscount
                                                        }
                                                    </Typography>
                                                    <Box
                                                        sx={{ width: "100px" }}
                                                    >
                                                        <Typography align="right">
                                                            {order.totalDiscount
                                                                .netPrice > 0
                                                                ? "-"
                                                                : ""}
                                                            {tenants[
                                                                selectedTenant
                                                            ].settings
                                                                .taxIncluded
                                                                ? order
                                                                      .totalDiscount
                                                                      .netPrice
                                                                : order
                                                                      .totalDiscount
                                                                      .grossPrice}
                                                        </Typography>
                                                    </Box>
                                                    <Typography>
                                                        {
                                                            order.totalDiscount
                                                                .priceCurrency
                                                        }
                                                    </Typography>
                                                </Stack>
                                                <Stack
                                                    direction={"row"}
                                                    spacing={1}
                                                >
                                                    <Typography>
                                                        {
                                                            translation.order
                                                                .total
                                                        }
                                                    </Typography>
                                                    <Box
                                                        sx={{ width: "100px" }}
                                                    >
                                                        <Typography align="right">
                                                            {tenants[
                                                                selectedTenant
                                                            ].settings
                                                                .taxIncluded
                                                                ? order
                                                                      .finalPrice
                                                                      .netPrice
                                                                : order
                                                                      .finalPrice
                                                                      .grossPrice}
                                                        </Typography>
                                                    </Box>
                                                    <Typography>
                                                        {
                                                            order.finalPrice
                                                                .priceCurrency
                                                        }
                                                    </Typography>
                                                </Stack>
                                            </Stack>
                                        </Stack>
                                    </Stack>
                                </Paper>

                                <Paper sx={{ padding: 2 }}>
                                    <Stack spacing={2}>
                                        <Typography>
                                            {translation.order.customer}:
                                        </Typography>
                                        <Stack>
                                            <Typography color={"primary"}>
                                                {order.customer.firstName !==
                                                null
                                                    ? order.customer.firstName +
                                                      " " +
                                                      order.customer.lastName
                                                    : order.shippingAddress
                                                          .name}
                                            </Typography>
                                            <Typography color={"text.disabled"}>
                                                {order.customer.email}
                                            </Typography>
                                            <Typography color={"text.disabled"}>
                                                {order.customer.phoneNumber}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                </Paper>
                                <Paper sx={{ padding: 2 }}>
                                    <Stack spacing={2}>
                                        <Stack direction={"row"} spacing={1}>
                                            <Typography>
                                                {translation.order.delivery}:
                                            </Typography>
                                            {order.shippingMethod ? (
                                                <Typography
                                                    sx={{
                                                        fontWeight: 500,
                                                    }}
                                                    color={"primary.dark"}
                                                >
                                                    {order.shippingMethod.name}
                                                </Typography>
                                            ) : (
                                                ""
                                            )}
                                        </Stack>{" "}
                                        {awb === null ? (
                                            <Box>
                                                <LoadingButton
                                                    onClick={() =>
                                                        generateAwb()
                                                    }
                                                    loading={loadingAwb}
                                                >
                                                    {
                                                        translation.order
                                                            .generateAwb
                                                    }
                                                </LoadingButton>
                                                <Typography color="error">
                                                    {awbError}
                                                </Typography>
                                            </Box>
                                        ) : (
                                            <Typography>
                                                Awb: {awb.awb}
                                            </Typography>
                                        )}
                                    </Stack>
                                </Paper>
                                <Paper sx={{ padding: 2 }}>
                                    <Stack spacing={2}>
                                        <Typography>
                                            {translation.order.shippingAddress}:
                                        </Typography>
                                        <Box>
                                            <Typography color={"text.disabled"}>
                                                {order.shippingAddress.name}
                                            </Typography>
                                            <Typography color={"text.disabled"}>
                                                {
                                                    order.shippingAddress
                                                        .phoneNumber
                                                }
                                            </Typography>
                                            <Typography color={"text.disabled"}>
                                                {
                                                    order.shippingAddress
                                                        .streetAddress
                                                }
                                            </Typography>
                                            <Typography color={"text.disabled"}>
                                                {order.shippingAddress.city +
                                                    ", " +
                                                    order.shippingAddress
                                                        .county +
                                                    ", " +
                                                    JSON.parse(
                                                        order.shippingAddress
                                                            .country
                                                    ).label}
                                            </Typography>
                                            <Typography color={"text.disabled"}>
                                                {
                                                    order.shippingAddress
                                                        .postalCode
                                                }
                                            </Typography>
                                        </Box>
                                    </Stack>
                                </Paper>
                                <Paper sx={{ padding: 2 }}>
                                    <Stack spacing={2}>
                                        <Typography>
                                            {translation.order.billingAddress}:
                                        </Typography>
                                        <Box>
                                            <Typography color={"text.disabled"}>
                                                {order.billingAddress.name}
                                            </Typography>
                                            <Typography color={"text.disabled"}>
                                                {
                                                    order.billingAddress
                                                        .phoneNumber
                                                }
                                            </Typography>
                                            <Typography color={"text.disabled"}>
                                                {
                                                    order.billingAddress
                                                        .streetAddress
                                                }
                                            </Typography>
                                            <Typography color={"text.disabled"}>
                                                {order.billingAddress.city +
                                                    ", " +
                                                    order.billingAddress
                                                        .county +
                                                    ", " +
                                                    JSON.parse(
                                                        order.billingAddress
                                                            .country
                                                    ).label}
                                            </Typography>
                                            <Typography color={"text.disabled"}>
                                                {
                                                    order.billingAddress
                                                        .postalCode
                                                }
                                            </Typography>
                                        </Box>
                                    </Stack>
                                </Paper>
                            </Stack>
                        </Box>
                    )}
                </>
            )}
        </>
    );
};

export default OrderView;
