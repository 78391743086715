import { useEffect } from "react";
import { useUserContext } from "components/contexts/UserContext";
import { routeNames } from "routes";
import { getTenantsByUser } from "services/tenants";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { logout } from "services/users";

const SelectTenant = () => {
  const {
    userId,
    selectTenant,
    selectedTenant,
    tenants,
    setTenants,
    tenantsIsLoading,
    setTennantsIsLoading,
    updateTrigger,
  } = useUserContext();

  useEffect(() => {
    setTennantsIsLoading(true);
    const prevSelectedTenant = localStorage.getItem("selectedTenant");
    if (userId) {
      getTenantsByUser(userId)
        .then((tenants) => {
          console.log(tenants);
          if (tenants.data) {
            setTenants(tenants.data["hydra:member"]);
            if (
              prevSelectedTenant === null ||
              tenants.data["hydra:member"].length <= prevSelectedTenant
            ) {
              if (tenants.data["hydra:member"].length <= selectedTenant) {
                selectTenant(0);
              } else {
                selectTenant(selectedTenant);
              }
            } else {
              selectTenant(parseInt(prevSelectedTenant));
            }
            setTennantsIsLoading(false);
          }
        })
        .catch((e) => {
          // no need for this, now checking jwt expiry time.
          if (e.response.status === 401) {
            logout().then(() => (window.location.href = routeNames.home));
          }
        });
    }
  }, [updateTrigger, userId]);
  return (
    <>
      {tenants && tenants.length > 1 && (
        <FormControl variant="standard" sx={{ minWidth: 120 }}>
          <Select
            id="select-tenant"
            value={tenantsIsLoading ? "" : selectedTenant}
            onChange={(e) => {
              selectTenant(e.target.value);
            }}
          >
            {tenants.map((tenant: any, index: number) => {
              return (
                <MenuItem key={tenant.companyName + tenant.id} value={index}>
                  {tenant.companyName}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      )}
    </>
  );
};

export default SelectTenant;
