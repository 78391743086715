import React from "react";
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import Layout from "components/Layout";
import CleanLayout from "components/CleanLayout";
import PageNotFound from "pages/PageNotFound";
import routes from "routes";
import { specialRoutes } from "routes";
import PrivateRoute from "components/PrivateRoute";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { RoundaboutLeft } from "@mui/icons-material";

const theme = createTheme({
    palette: {
        primary: {
            main: "#6E00F5",
            dark: "#5800C4",
        },
        secondary: {
            main: "#F1C557",
        },
        background: {
            default: "#F5F5F5",
        },
        text: {
            disabled: "rgba(10, 10, 10, 0.38)",
        },
    },
    typography: {
        fontFamily: [
            "Lexend Deca",
            "-apple-system",
            "BlinkMacSystemFont",
            '"Segoe UI"',
            "Roboto",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(","),
        h1: {
            fontSize: 96,
            lineHeight: "116.7%",
        },
        h2: {
            fontSize: 60,
            lineHeight: "120%",
        },
        h3: {
            fontSize: 48,
            lineHeight: "116.7%",
        },
        h4: {
            fontSize: 34,
            lineHeight: "123.5%",
            fontWeight: 600,
        },
        h5: {
            fontSize: 24,
            lineHeight: "133.4%",
            fontWeight: 500,
        },
        h6: {
            fontSize: 20,
            lineHeight: "160%",
            fontWeight: 300,
        },
        body1: {
            fontSize: 18,
            lineHeight: "150%",
        },
        body2: {
            fontSize: 16,
            fontWeight: 500,
            lineHeight: "143%",
        },
        subtitle1: {
            fontSize: 16,
            lineHeight: "175%",
        },
        subtitle2: {
            fontSize: 14,
            lineHeight: "157%",
            fontWeight: 500,
        },
        overline: {
            fontSize: 14,
            lineHeight: "200%",
        },
        caption: {
            fontSize: 12,
            lineHeight: "166%",
        },
        button: {
            fontSize: 16,
        },
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                colorPrimary: {
                    backgroundColor: "white",
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                head: {
                    fontWeight: 300,
                    fontSize: 20,
                    lineHeight: "30px",
                },
                body: {
                    fontWeight: 500,
                    fontSize: 16,
                },
            },
        },
        MuiIcon: {
            styleOverrides: {
                fontSizeLarge: {
                    fontSize: "30px",
                },
            },
        },
        MuiSvgIcon: {
            styleOverrides: {
                fontSizeLarge: {
                    fontSize: "30px",
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: "0 8px",
                },
            },
        },
        MuiAlert: {
            styleOverrides: {
                icon: {
                    width: "1.4em",
                    height: "1.4em",
                },
                message: {
                    fontSize: 22,
                },
            },
        },
        MuiAvatar: {
            styleOverrides: {
                rounded: {
                    borderRadius: "0 8px",
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                rounded: {
                    borderRadius: "0 8px",
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                icon: {
                    color: "#6E00F5",
                },
            },
        },
    },
});

function App() {
    return (
        <ThemeProvider theme={theme}>
            <Router>
                <Routes>
                    {routes.map((route: any, index: number) => (
                        <Route
                            path={route.path}
                            key={"route-key-" + index}
                            element={
                                route.authenticatedRoute ? (
                                    <Layout
                                        hasSettingsMenu={route.settingsRoute}
                                        hasEcommerceDesignMenu={
                                            route.ecommerceDesignMenu
                                        }
                                        hasEcoomerceCustomOptionsMenu={
                                            route.ecommerceCustomOptionsMenu
                                        }
                                    >
                                        <PrivateRoute>
                                            <route.component />
                                        </PrivateRoute>
                                    </Layout>
                                ) : (
                                    <Layout>
                                        <route.component />
                                    </Layout>
                                )
                            }
                        />
                    ))}

                    {specialRoutes.map((route: any, index: number) => (
                        <Route
                            path={route.path}
                            key={"sRoute-key-" + index}
                            element={
                                <CleanLayout>
                                    <route.component />
                                </CleanLayout>
                            }
                        />
                    ))}
                    <Route
                        path="*"
                        element={
                            <Layout>
                                <PageNotFound />
                            </Layout>
                        }
                    />
                </Routes>
            </Router>
        </ThemeProvider>
    );
}

export default App;
