import axios from "helpers/axios";
import { backendURL } from "config";
import {
    IAppIntegrations,
    IBrandBook,
    ICoupon,
    ICourier,
    IOrder,
    ISettings,
    IShippingMethod,
    ITax,
} from "interfaces";

export async function createNewTenant(tenant: {
    companyName: string;
    companyLegalName: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    state: string;
    country: string;
    users: Array<string>;
}) {
    const URL = backendURL + "/tenants";

    const res = await axios.post(
        URL,
        JSON.stringify({
            ...tenant,
        })
    );

    return res;
}

export async function deleteTenant(tenantId: string) {
    const URL = backendURL + "/tenants/" + tenantId;
    const res = axios
        .delete(URL)
        .then((res) => res)
        .catch((error) => {
            throw error;
        });
    return res;
}

export async function getPaymentOptionsByTenant(tenantId: string) {
    const URL = backendURL + "/payments?tenant.id=" + tenantId;
    const res = axios
        .get(URL)
        .then((res) => res)
        .catch((error) => {
            throw error;
        });
    return res;
}

export async function getShippingMethodsByTenant(tenantId: string) {
    const URL = backendURL + "/shipping_methods?tenant.id=" + tenantId;
    const res = axios
        .get(URL)
        .then((res) => res)
        .catch((error) => {
            throw error;
        });
    return res;
}

export async function createShippingMethod(shippingMethod: IShippingMethod) {
    const URL = backendURL + "/shipping_methods";
    const res = axios
        .post(URL, shippingMethod)
        .then((res) => res)
        .catch((error) => {
            throw error;
        });
    return res;
}

export async function updateShippingMethod(shippingMethod: IShippingMethod) {
    const URL = backendURL + "/shipping_methods/" + shippingMethod.id;
    const res = axios
        .patch(URL, shippingMethod)
        .then((res) => res)
        .catch((error) => {
            throw error;
        });
    return res;
}

export async function deleteShippingMethod(shippingMethod: IShippingMethod) {
    const URL = backendURL + "/shipping_methods/" + shippingMethod.id;
    const res = axios
        .delete(URL)
        .then((res) => res)
        .catch((error) => {
            throw error;
        });
    return res;
}

export async function getPolicyTemplates() {
    const URL = backendURL + "/policies/get/templates";
    const res = axios
        .get(URL)
        .then((res) => res)
        .catch((error) => {
            throw error;
        });
    return res;
}

export async function getPoliciesByTenant(tenantId: string) {
    const URL = backendURL + "/policies?tenant.id=" + tenantId;
    const res = axios
        .get(URL)
        .then((res) => res)
        .catch((error) => {
            throw error;
        });
    return res;
}
export async function createSettings(settings: ISettings) {
    const URL = backendURL + "/settings";
    const res = axios
        .post(URL, settings)
        .then((res) => res)
        .catch((error) => {
            throw error;
        });
    return res;
}

export async function updateSettings(settings: ISettings) {
    const URL = backendURL + "/settings/" + settings.id;
    const res = axios
        .patch(URL, settings)
        .then((res) => res)
        .catch((error) => {
            throw error;
        });
    return res;
}

export async function createTax(tax: ITax) {
    const URL = backendURL + "/taxes";
    const res = axios
        .post(URL, tax)
        .then((res) => res)
        .catch((error) => {
            throw error;
        });
    return res;
}

export async function updateTax(tax: ITax) {
    const URL = backendURL + "/taxes/" + tax.id;
    const res = axios
        .patch(URL, tax)
        .then((res) => res)
        .catch((error) => {
            throw error;
        });
    return res;
}

export async function getTaxHasProducts(tax: ITax) {
    const URL = backendURL + "/taxes/" + tax.id + "/hasProducts";
    const res = axios
        .get(URL)
        .then((res) => res)
        .catch((error) => {
            throw error;
        });
    return res;
}

export async function getTaxesByTenant(tenantId: string) {
    const URL = backendURL + "/taxes?isActive=true&tenant.id=" + tenantId;
    const res = axios
        .get(URL)
        .then((res) => res)
        .catch((error) => {
            throw error;
        });
    return res;
}

export async function getCouponsByTenant(
    tenantId: string,
    page = 1,
    itemsPerPage = 10,
    orderBy = "id",
    order = "desc"
) {
    const URL =
        backendURL +
        "/coupons?tenant.id=" +
        tenantId +
        "&page=" +
        page +
        "&itemsPerPage=" +
        itemsPerPage +
        "&order[" +
        orderBy +
        "]=" +
        order;
    const res = axios
        .get(URL)
        .then((res) => res)
        .catch((error) => {
            throw error;
        });
    return res;
}

export async function getCoupon(couponId: string) {
    const URL = backendURL + "/coupons/" + couponId;
    const res = axios
        .get(URL)
        .then((res) => res)
        .catch((error) => {
            throw error;
        });
    return res;
}

export async function createCoupon(coupon: ICoupon) {
    const URL = backendURL + "/coupons";
    const res = axios
        .post(URL, coupon)
        .then((res) => res)
        .catch((error) => {
            throw error;
        });
    return res;
}

export async function updateCoupon(coupon: ICoupon) {
    const URL = backendURL + "/coupons/" + coupon.id;
    const res = axios
        .patch(URL, coupon)
        .then((res) => res)
        .catch((error) => {
            throw error;
        });
    return res;
}

export async function createOrder(order: IOrder) {
    const URL = backendURL + "/orders";
    const res = axios
        .post(URL, order)
        .then((res) => res)
        .catch((error) => {
            throw error;
        });
    return res;
}

export async function createCourier(courier: ICourier) {
    const URL = backendURL + "/couriers";
    const res = axios
        .post(URL, courier)
        .then((res) => res)
        .catch((error) => {
            throw error;
        });
    return res;
}

export async function updateCourier(courier: ICourier) {
    const URL = backendURL + "/couriers/" + courier.id;
    const res = axios
        .patch(URL, courier)
        .then((res) => res)
        .catch((error) => {
            throw error;
        });
    return res;
}

export async function deleteCourier(courier: ICourier) {
    const URL = backendURL + "/couriers/" + courier.id;
    const res = axios
        .delete(URL)
        .then((res) => res)
        .catch((error) => {
            throw error;
        });
    return res;
}

export async function getServicesByCourier(courier: ICourier) {
    const URL = backendURL + "/couriers/" + courier.id + "/services";
    const res = axios
        .get(URL)
        .then((res) => res)
        .catch((error) => {
            throw error;
        });
    return res;
}

export async function getPickUpPointsByCourier(courier: ICourier) {
    const URL = backendURL + "/couriers/" + courier.id + "/pickup";
    const res = axios
        .get(URL)
        .then((res) => res)
        .catch((error) => {
            throw error;
        });
    return res;
}

export async function getCouriersByTenant(tenantId: string) {
    const URL = backendURL + "/couriers?tenant.id=" + tenantId;
    const res = axios
        .get(URL)
        .then((res) => res)
        .catch((error) => {
            throw error;
        });
    return res;
}

export async function getSettingsByTenant(tenantId: string) {
    const URL = backendURL + "/settings?tenant.id=" + tenantId;
    const res = axios
        .get(URL)
        .then((res) => res)
        .catch((error) => {
            throw error;
        });
    return res;
}

export async function getDropTagsByTenant(tenantId: string) {
    const URL = backendURL + "/drop_tags?tenant.id=" + tenantId;
    const res = axios
        .get(URL)
        .then((res) => res)
        .catch((error) => {
            throw error;
        });
    return res;
}

export async function getIntegrationsByTenant(tenantId: string) {
    const URL = backendURL + "/app_integrations?tenant.id=" + tenantId;
    const res = axios
        .get(URL)
        .then((res) => res)
        .catch((error) => {
            throw error;
        });
    return res;
}

export async function createIntegrations(integrations: IAppIntegrations) {
    const URL = backendURL + "/app_integrations";
    const res = axios
        .post(URL, integrations)
        .then((res) => res)
        .catch((error) => {
            throw error;
        });
    return res;
}

export async function updateIntegrations(integrations: IAppIntegrations) {
    const URL = backendURL + "/app_integrations/" + integrations.id;
    const res = axios
        .patch(URL, integrations)
        .then((res) => res)
        .catch((error) => {
            throw error;
        });
    return res;
}

export async function getBrandBookByTenant(tenantId: string) {
    const URL = backendURL + "/brand_books?tenant.id=" + tenantId;
    const res = axios
        .get(URL)
        .then((res) => res)
        .catch((error) => {
            throw error;
        });
    return res;
}

export async function createBrandBook(brandBook: IBrandBook) {
    const URL = backendURL + "/brand_books";
    const res = axios
        .post(URL, brandBook)
        .then((res) => res)
        .catch((error) => {
            throw error;
        });
    return res;
}

export async function updateBrandBook(brandBook: IBrandBook) {
    const URL = backendURL + "/brand_books/" + brandBook.id;
    const res = axios
        .patch(URL, brandBook)
        .then((res) => res)
        .catch((error) => {
            throw error;
        });
    return res;
}

export async function getUserById(userId: string) {
    const URL = backendURL + "/users/" + userId;
    const res = axios
        .get(URL)
        .then((res) => res)
        .catch((error) => {
            throw error;
        });
    return res;
}

export async function createPayment(
    tenantId: string,
    label: string,
    type: string,
    iban: string,
    description: string,
    info: string
) {
    const URL = backendURL + "/payments";
    const res = axios
        .post(URL, {
            tenant: "/tenants/" + tenantId,
            label: label,
            type: type,
            iban: iban,
            description: description,
            info: info,
            isActive: true,
        })
        .then((res) => res)
        .catch((error) => {
            throw error;
        });
    return res;
}

export async function updatePayment(payment: any) {
    const URL = backendURL + "/payments/" + payment.id;
    const res = axios
        .patch(URL, payment)
        .then((res) => res)
        .catch((error) => {
            throw error;
        });
    return res;
}

export async function deletePayment(payment: any) {
    const URL = backendURL + "/payments/" + payment.id;
    const res = axios
        .delete(URL)
        .then((res) => res)
        .catch((error) => {
            throw error;
        });
    return res;
}

export async function createPolicy(
    name: string,
    slug: string,
    content: string,
    tenantId: string
) {
    const URL = backendURL + "/policies";
    const res = axios
        .post(URL, {
            tenant: "/tenants/" + tenantId,
            name: name,
            slug: slug,
            content: content,
            isActive: true,
        })
        .then((res) => res)
        .catch((error) => {
            throw error;
        });
    return res;
}

export async function updatePolicy(payment: any) {
    const URL = backendURL + "/policies/" + payment.id;
    const res = axios
        .patch(URL, payment)
        .then((res) => res)
        .catch((error) => {
            throw error;
        });
    return res;
}

export async function deletePolicy(payment: any) {
    const URL = backendURL + "/policies/" + payment.id;
    const res = axios
        .delete(URL)
        .then((res) => res)
        .catch((error) => {
            throw error;
        });
    return res;
}

export async function postLinkAccount(paymentId: string) {
    const URL = backendURL + "/stripe/link-account";

    const res = axios
        .post(URL, {
            paymentId: paymentId,
        })
        .then((res) => res)
        .catch((error) => {
            throw error;
        });
    return res;
}

export async function getAccountOnboardingStatus(paymentId: string) {
    const URL = backendURL + "/stripe/get-account";

    const res = axios
        .post(URL, {
            paymentId: paymentId,
        })
        .then((res) => res)
        .catch((error) => {
            throw error;
        });
    return res;
}

export async function getTenantsByUser(userId: string) {
    const URL = backendURL + "/tenants?users.id=" + userId;
    const res = axios
        .get(URL)
        .then((res) => res)
        .catch((error) => {
            throw error;
        });
    return res;
}

export async function getAppsByTenantId(tenantId: string) {
    const URL = backendURL + "/apps?tenant.id=" + tenantId;
    const res = axios
        .get(URL)
        .then((res) => res)
        .catch((error) => {
            throw error;
        });
    return res;
}

export async function getAppDeploymentStatus(appId: string) {
    const URL = backendURL + "/application/deployment";
    const res = axios
        .post(URL, {
            appId: appId,
        })
        .then((res) => res)
        .catch((error) => {
            throw error;
        });
    return res;
}

export async function updateUser(
    user: {
        firstName: string;
        lastName: string;
        email: string;
        photo: any;
        nickname?: string;
    },
    userId: string
) {
    const URL = backendURL + "/users/" + userId;

    const res = axios
        .patch(
            URL,
            JSON.stringify({
                ...user,
            })
        )
        .then((res) => res)
        .catch((error) => {
            throw error;
        });
    return res;
}

export async function updateTenant(
    tenant: {
        companyName: string;
        companyLegalName: string;
        addressLine1: string;
        addressLine2: string;
        city: string;
        state: string;
        country: string;
    },
    tenantId: string
) {
    const URL = backendURL + "/tenants/" + tenantId;

    const res = await axios
        .patch(
            URL,
            JSON.stringify({
                ...tenant,
            })
        )
        .then((res) => res)
        .catch((error) => {
            throw error;
        });
    return res;
}

export function searchCustomers(email: string) {
    const URL = backendURL + "/customers?email=" + email;
    const res = axios
        .get(URL)
        .then((res) => res)
        .catch((error) => {
            throw error;
        });
    return res;
}
