import React from "react";
import useTranslation from "components/customHooks/translations";
import { useLocation, useNavigate } from "react-router-dom";
import { routeNames } from "routes";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {
    AutoStoriesOutlined,
    FilterBAndWOutlined,
    KebabDiningOutlined,
    SchemaOutlined,
    StadiumOutlined,
    StorefrontOutlined,
} from "@mui/icons-material";

interface LinkTabProps {
    label?: string;
    href?: string;
    value?: string;
    icon?: any;
}

function LinkTab(props: LinkTabProps) {
    return <Tab sx={{ minHeight: "50px" }} iconPosition="start" {...props} />;
}

const EcommerceCustomOptionsMenu = () => {
    const translation = useTranslation();
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        navigate(newValue);
    };

    return (
        <Box
            sx={{
                width: "100%",
                mb: 6,
                display: "flex",
                justifyContent: "flex-end",
            }}
        >
            <Tabs onChange={handleChange} value={pathname}>
                <LinkTab
                    label={translation.applicationTitle}
                    value={routeNames.applicationSettings}
                />
                <LinkTab
                    label={translation.paymentsTitle}
                    value={routeNames.payments}
                />
                <LinkTab
                    label={translation.shippingTitle}
                    value={routeNames.shipping}
                />
                <LinkTab
                    label={translation.taxesTitle}
                    value={routeNames.taxes}
                />
                <LinkTab
                    label={translation.integrationsTitle}
                    value={routeNames.integrations}
                />
            </Tabs>
        </Box>
    );
};

export default EcommerceCustomOptionsMenu;
