import React, { useEffect, useState } from "react";
import { useUserContext } from "components/contexts/UserContext";
import * as Yup from "yup";
import useTranslation from "components/customHooks/translations";
import FormTextField from "components/Form/FormTextField/FormTextField";
import SelectField from "components/Form/SelectField";
import { Formik } from "formik";
import { deleteDoTask, newDoTask, updateDoTask } from "services/do";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Drawer,
    MenuItem,
    Stack,
    Typography,
} from "@mui/material";
import { formDrawerWidth } from "components/Form/constants";
import { useSnackbarContext } from "components/contexts/SnackbarContext";
import { IDoTask, IDropTag } from "interfaces";
import { errorMessage } from "helpers";
import dayjs from "dayjs";
import DatePickerField from "components/Form/DatePickerField";
import CheckboxField from "components/Form/CheckboxField/CheckboxField";
import CheckboxAudioField from "components/Form/CheckboxAudioField/CheckboxAudioField";
import { LoadingButton } from "@mui/lab";
import AutocompleteTagsField from "components/Form/AutocompleteTagsField";
import { getDropTagsByTenant } from "services/tenants";

const DoTaskForm = ({
    showNewDoTask,
    setShowNewDoTask,
    doTaskToEdit,
    setDoTaskToEdit,
    updateTrigger,
    setUpdateTrigger,
    setNewDoTask,
}: {
    showNewDoTask: boolean;
    setShowNewDoTask: React.Dispatch<React.SetStateAction<boolean>>;
    doTaskToEdit?: IDoTask | null;
    setDoTaskToEdit?: React.Dispatch<React.SetStateAction<IDoTask | null>>;
    updateTrigger: boolean;
    setUpdateTrigger: React.Dispatch<React.SetStateAction<boolean>>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setNewDoTask?: any;
}) => {
    const { userId, selectedTenant, tenants } = useUserContext();
    const [deleteLoading, setDeleteLoading] = useState(false);
    const { setSuccessMessage, setErrorMessage } = useSnackbarContext();
    const [openDelete, setOpenDelete] = useState(false);
    const [dropTags, setDropTags] = useState<IDropTag[]>([]);

    const translation = useTranslation();

    const doTaskSchema = Yup.object().shape({
        name: Yup.string()
            .min(2, translation.signUpShortError)
            .max(50, translation.signUpLongError)
            .required(translation.required),
        dueDate: Yup.string().required(translation.required),
    });

    const handleDeleteDoTask = () => {
        if (doTaskToEdit) {
            setDeleteLoading(true);
            deleteDoTask(doTaskToEdit)
                .then((res) => {
                    setSuccessMessage(translation.deletedMessage);
                    setOpenDelete(false);
                    setShowNewDoTask(false);
                    setUpdateTrigger(!updateTrigger);
                })
                .catch((e) => setErrorMessage(errorMessage(e)))
                .finally(() => {
                    setDeleteLoading(false);
                });
        }
    };

    useEffect(() => {
        if (tenants !== null) {
            getDropTagsByTenant(tenants[selectedTenant].id)
                .then((res) => {
                    setDropTags(res.data["hydra:member"]);
                })
                .catch((e) => console.log(e));
        }
    }, [selectedTenant, tenants, updateTrigger]);

    return (
        <>
            <Drawer
                anchor="right"
                open={showNewDoTask}
                onClose={() => {
                    setShowNewDoTask(false);
                    if (setDoTaskToEdit) {
                        setDoTaskToEdit(null);
                    }
                }}
                sx={{
                    "& .MuiDrawer-paper": { width: formDrawerWidth },
                }}
            >
                <Stack
                    justifyContent={"space-between"}
                    sx={{ p: 4, height: "100%" }}
                >
                    <Box>
                        <Formik
                            initialValues={{
                                name: doTaskToEdit ? doTaskToEdit.name : "",
                                description: doTaskToEdit
                                    ? doTaskToEdit.description
                                        ? doTaskToEdit.description
                                        : ""
                                    : "",
                                dueDate: doTaskToEdit
                                    ? doTaskToEdit.dueDate
                                    : dayjs().toISOString(),
                                done: doTaskToEdit ? doTaskToEdit.done : false,
                                tags: doTaskToEdit
                                    ? doTaskToEdit.tags
                                        ? doTaskToEdit.tags
                                        : []
                                    : [],
                            }}
                            validationSchema={doTaskSchema}
                            enableReinitialize
                            onSubmit={(values, { setSubmitting }) => {
                                setSubmitting(true);

                                if (doTaskToEdit) {
                                    updateDoTask({
                                        ...doTaskToEdit,
                                        name: values.name,
                                        description: values.description,
                                        dueDate: values.dueDate,
                                        done: values.done,
                                        tags: values.tags,
                                    })
                                        .then(() => {
                                            setSubmitting(false);
                                            setShowNewDoTask(false);
                                            if (setDoTaskToEdit) {
                                                setDoTaskToEdit(null);
                                            }
                                            setSuccessMessage(
                                                translation.savedMessage
                                            );
                                            setUpdateTrigger(!updateTrigger);
                                        })
                                        .catch((e) => {
                                            setErrorMessage(errorMessage(e));
                                            setSubmitting(false);
                                        });
                                } else {
                                    newDoTask({
                                        name: values.name,
                                        description: values.description,
                                        dueDate: values.dueDate,
                                        owner: "/users/" + userId,
                                        tenant:
                                            "/tenants/" +
                                            tenants[selectedTenant].id,
                                        tags: values.tags,
                                    })
                                        .then((res) => {
                                            setSubmitting(false);
                                            setShowNewDoTask(false);
                                            if (setDoTaskToEdit) {
                                                setDoTaskToEdit(null);
                                            }
                                            setSuccessMessage(
                                                translation.savedMessage
                                            );
                                            setUpdateTrigger(!updateTrigger);
                                            if (setNewDoTask) {
                                                setNewDoTask(res.data["@id"]);
                                            }
                                        })
                                        .catch((e) => {
                                            setErrorMessage(errorMessage(e));
                                            setSubmitting(false);
                                        });
                                }
                            }}
                        >
                            {({ handleSubmit, isSubmitting }) => (
                                <form noValidate onSubmit={handleSubmit}>
                                    <Stack spacing={2}>
                                        <Typography variant="h5">
                                            {doTaskToEdit
                                                ? translation.do.editDo
                                                : translation.do.newDo}
                                        </Typography>
                                        <Stack spacing={4}>
                                            <FormTextField
                                                label={translation.do.nameLabel}
                                                name="name"
                                            />
                                            <FormTextField
                                                label={
                                                    translation.do
                                                        .descriptionLabel
                                                }
                                                multiline={true}
                                                name="description"
                                            />
                                            <DatePickerField
                                                label={translation.do.dueDate}
                                                name="dueDate"
                                            />
                                            <AutocompleteTagsField
                                                options={dropTags}
                                                label={translation.do.tagsLabel}
                                                name="tags"
                                            />
                                            <CheckboxAudioField
                                                name="done"
                                                label={translation.do.doneLabel}
                                            />

                                            <Stack
                                                direction={"row"}
                                                spacing={2}
                                                justifyContent={"space-between"}
                                            >
                                                <Button
                                                    variant={"outlined"}
                                                    onClick={() => {
                                                        setShowNewDoTask(false);
                                                        if (setDoTaskToEdit) {
                                                            setDoTaskToEdit(
                                                                null
                                                            );
                                                        }
                                                    }}
                                                >
                                                    {translation.closeButton}
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    type="submit"
                                                    disabled={isSubmitting}
                                                >
                                                    {translation.saveButton}
                                                </Button>
                                            </Stack>
                                        </Stack>
                                    </Stack>
                                </form>
                            )}
                        </Formik>
                    </Box>
                    <Box
                        display={"flex"}
                        justifyContent={"flex-end"}
                        alignItems={"flex-end"}
                    >
                        <Button
                            variant="outlined"
                            color="error"
                            onClick={() => setOpenDelete(true)}
                        >
                            {translation.deleteButton}
                        </Button>
                    </Box>
                </Stack>
            </Drawer>
            <Dialog
                open={openDelete}
                onClose={() => {
                    setOpenDelete(false);
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {translation.do.deleteConfirmMessage}
                </DialogTitle>
                <DialogContent>
                    <Typography>{doTaskToEdit?.name}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant={"outlined"}
                        onClick={() => {
                            setOpenDelete(false);
                        }}
                    >
                        {translation.closeButton}
                    </Button>

                    <LoadingButton
                        onClick={() => handleDeleteDoTask()}
                        variant="contained"
                        loading={deleteLoading}
                    >
                        {translation.deleteButton}
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default DoTaskForm;
