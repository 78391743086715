import React, { useEffect, useState } from "react";
import { useUserContext } from "components/contexts/UserContext";
import useTranslation from "components/customHooks/translations";
import FormTextField from "components/Form/FormTextField/FormTextField";
import { Formik } from "formik";
import {
  createBrandBook,
  getBrandBookByTenant,
  updateBrandBook,
} from "services/tenants";
import { Box, Grid, Paper, Stack, Typography } from "@mui/material";
import { IBrandBook } from "interfaces";
import LoadingIcon from "components/Feedback/LoadingIcon";
import { LoadingButton } from "@mui/lab";
import InfoPopover from "components/InfoPopover";
import DropZoneBrandField from "components/Form/DropZoneBrandField";
import ColorInputField from "components/Form/ColorInputField";
import ArraySocialField from "components/Form/ArraySocialField";
import { useSnackbarContext } from "components/contexts/SnackbarContext";
import { errorMessage } from "helpers";

const BrandBook = () => {
  const { tenantsIsLoading, selectedTenant, tenants } = useUserContext();
  const [brandBook, setBrandBook] = useState<IBrandBook | null>(null);
  const [brandBookLoading, setBrandBookLoading] = useState(true);
  const { setErrorMessage, setSuccessMessage } = useSnackbarContext();
  const translation = useTranslation();
  const [updateTrigger, setUpdateTrigger] = useState(false);

  useEffect(() => {
    setBrandBookLoading(true);

    if (tenants !== null) {
      getBrandBookByTenant(tenants[selectedTenant].id)
        .then((res) => {
          if (res.data["hydra:member"].length > 0) {
            setBrandBook(res.data["hydra:member"][0]);
          } else {
            setBrandBook(null);
          }
          setBrandBookLoading(false);
        })
        .catch((e) => console.log(e));
    }
  }, [selectedTenant, tenants, updateTrigger]);

  return (
    <>
      <Formik
        initialValues={
          brandBook
            ? {
                logo: brandBook.logo,
                squareLogo: brandBook.squareLogo,
                slogan: brandBook.slogan,
                shortDescription: brandBook.shortDescription,
                coverImage: brandBook.coverImage,
                primaryColor: brandBook.primaryColor,
                secondaryColor: brandBook.secondaryColor,
                socialLinks:
                  brandBook.socialLinks !== undefined
                    ? [...brandBook.socialLinks, ""]
                    : [""],
              }
            : {
                logo: null,
                squareLogo: null,
                slogan: "",
                shortDescription: "",
                coverImage: null,
                primaryColor: "",
                secondaryColor: "",
                socialLinks: [""],
              }
        }
        onSubmit={(values, actions) => {
          actions.setSubmitting(true);
          const socialLinks = [...values.socialLinks];
          socialLinks.pop();
          let flag = true;
          socialLinks.forEach((socialLink) => {
            if (!/^(ftp|http|https):\/\/[^ "]+$/.test(socialLink)) {
              actions.setFieldError("socialLinks", "Urls not valid");
              flag = false;
            }
          });

          if (!flag) {
            actions.setSubmitting(false);
            return;
          }
          if (!brandBook) {
            createBrandBook({
              logo: values.logo,
              squareLogo: values.squareLogo,
              coverImage: values.coverImage,
              primaryColor: values.primaryColor,
              secondaryColor: values.secondaryColor,
              shortDescription: values.shortDescription,
              slogan: values.slogan,
              socialLinks: socialLinks,
              tenant: "/tenants/" + tenants[selectedTenant].id,
            })
              .then(() => {
                setUpdateTrigger(!updateTrigger);
                setSuccessMessage(translation.savedMessage);
                actions.setSubmitting(false);
              })
              .catch((e) => {
                setErrorMessage(errorMessage(e));
                actions.setSubmitting(false);
              });
          } else {
            updateBrandBook({
              id: brandBook.id,
              logo: values.logo,
              squareLogo: values.squareLogo,
              coverImage: values.coverImage,
              primaryColor: values.primaryColor,
              secondaryColor: values.secondaryColor,
              shortDescription: values.shortDescription,
              slogan: values.slogan,
              socialLinks: socialLinks,
            })
              .then(() => {
                setUpdateTrigger(!updateTrigger);
                setSuccessMessage(translation.savedMessage);
                actions.setSubmitting(false);
              })
              .catch((e) => {
                setErrorMessage(errorMessage(e));
                actions.setSubmitting(false);
              });
          }
        }}
        enableReinitialize
      >
        {({ handleSubmit, errors, isSubmitting }) => {
          return (
            <form noValidate className="" onSubmit={handleSubmit}>
              <Grid container>
                <Grid item md={6}>
                  {" "}
                  <Stack spacing={2}>
                    <Paper sx={{ p: 2 }}>
                      <Stack spacing={2}>
                        <DropZoneBrandField
                          name="logo"
                          label={translation.brandBook.logoLabel}
                          labelDescription={
                            translation.brandBook.logoDescriptionLabel
                          }
                        ></DropZoneBrandField>
                      </Stack>
                    </Paper>
                    <Paper sx={{ p: 2 }}>
                      <Stack spacing={2}>
                        <DropZoneBrandField
                          name="squareLogo"
                          label={translation.brandBook.logoSquareLabel}
                          labelDescription={
                            translation.brandBook.logoSquareDescriptionLabel
                          }
                        ></DropZoneBrandField>
                      </Stack>
                    </Paper>
                    <Paper sx={{ p: 2 }}>
                      <Stack spacing={2}>
                        <DropZoneBrandField
                          name="coverImage"
                          label={translation.brandBook.coverImageLabel}
                          labelDescription={
                            translation.brandBook.coverImageDescriptionLabel
                          }
                        ></DropZoneBrandField>
                      </Stack>
                    </Paper>
                    <Paper sx={{ p: 2 }}>
                      <Stack spacing={2}>
                        <Stack direction={"row"} spacing={2}>
                          <Box flexGrow={1}>
                            <Stack>
                              <FormTextField
                                label={translation.brandBook.sloganLabel}
                                name="slogan"
                              />
                            </Stack>
                          </Box>
                          <InfoPopover
                            infoContent={
                              translation.brandBook.sloganDescription
                            }
                          />
                        </Stack>
                        <Stack direction={"row"} spacing={2}>
                          <Box flexGrow={1}>
                            <Stack>
                              <FormTextField
                                multiline={true}
                                label={
                                  translation.brandBook.shortDescriptionLabel
                                }
                                name="shortDescription"
                              />
                            </Stack>
                          </Box>
                          <InfoPopover
                            infoContent={
                              translation.brandBook.shortDescriptionDescription
                            }
                          />
                        </Stack>
                      </Stack>
                    </Paper>
                    <Paper sx={{ p: 2 }}>
                      <Stack spacing={2}>
                        <Stack>
                          <Stack direction={"row"} spacing={2}>
                            <Box flexGrow={1}>
                              <Stack>
                                <Typography>
                                  {translation.brandBook.primaryColorLabel}
                                </Typography>
                              </Stack>
                            </Box>
                            <InfoPopover
                              infoContent={
                                translation.brandBook.primaryColorDescription
                              }
                            />
                          </Stack>
                          <ColorInputField name="primaryColor"></ColorInputField>
                        </Stack>
                        <Stack>
                          <Stack direction={"row"} spacing={2}>
                            <Box flexGrow={1}>
                              <Stack>
                                <Typography>
                                  {translation.brandBook.secondaryColorLabel}
                                </Typography>
                              </Stack>
                            </Box>
                            <InfoPopover
                              infoContent={
                                translation.brandBook.secondaryColorDescription
                              }
                            />
                          </Stack>
                          <ColorInputField name="secondaryColor"></ColorInputField>
                        </Stack>
                      </Stack>
                    </Paper>
                    <Paper sx={{ p: 2 }}>
                      <Stack spacing={2}>
                        <ArraySocialField
                          name="socialLinks"
                          label={translation.brandBook.addSocialLinkLabel}
                        />
                        <Typography color={"error.main"}>
                          {errors?.socialLinks}
                        </Typography>
                      </Stack>
                    </Paper>
                    <Box display={"flex"} justifyContent={"flex-end"}>
                      <LoadingButton
                        variant="contained"
                        loading={isSubmitting}
                        type="submit"
                      >
                        {translation.brandBook.saveBrandBook}
                      </LoadingButton>
                    </Box>
                  </Stack>
                </Grid>
              </Grid>
            </form>
          );
        }}
      </Formik>
    </>
  );
};

export default BrandBook;
