import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Collapse,
  Divider,
  FormControl,
  IconButton,
  InputBase,
  InputLabel,
  Paper,
  Select,
  Stack,
  Switch,
  TextField,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import useTranslation from "components/customHooks/translations";
import {
  getDoTask,
  getDoTasks,
  getDoTasksByTenant,
  newDoTask,
  updateDoTask,
} from "services/do";
import dayjs from "dayjs";
import { useUserContext } from "components/contexts/UserContext";
import { useSnackbarContext } from "components/contexts/SnackbarContext";
import { IDoTask } from "interfaces";
import { useLoadingContext } from "components/contexts/LoadingContext";
import { delay, errorMessage, formattedDate } from "helpers";
import DoTaskForm from "./DoForm";
import { EditOutlined, HiveOutlined, SendOutlined } from "@mui/icons-material";
import { TransitionGroup } from "react-transition-group";
import ding from "assets/sounds/ding.mp3";

const Do = () => {
  const [audio] = useState(new Audio(ding));

  const { userId, selectedTenant, tenants } = useUserContext();
  const { setErrorMessage, setSuccessMessage } = useSnackbarContext();
  const { loading, setLoading } = useLoadingContext();
  const { pathname } = useLocation();
  const translation = useTranslation();
  const [newDoName, setNewDoName] = useState("");
  const [showFilters, setShowFilters] = useState(false);
  const [showNewDoTask, setShowNewDoTask] = useState(false);
  const [doTaskToEdit, setDoTaskToEdit] = useState<IDoTask | null>(null);
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [doneFilter, setDoneFilter] = useState(false);
  const [disableDone, setDisableDone] = useState(false);

  const [doTasks, setDoTasks] = useState<IDoTask[]>([]);

  const createDoTask = (e: any) => {
    if (e.keyCode === 13) {
      if (newDoName !== "") {
        newDoTask({
          name: newDoName,
          dueDate: dayjs().toISOString(),
          owner: "/users/" + userId,
          tenant: "/tenants/" + tenants[selectedTenant].id,
        })
          .then((response) => {
            setDoTasks((prev) => [
              response.data as unknown as IDoTask,
              ...prev,
            ]);
            setSuccessMessage(translation.savedMessage);
            setNewDoName("");
          })
          .catch((e) => setErrorMessage(e.response.data.message));
      }
    }
  };

  useEffect(() => {
    setLoading(true);

    if (tenants !== null) {
      getDoTasksByTenant(tenants[selectedTenant].id, doneFilter)
        .then((res) => {
          setDoTasks(res.data["hydra:member"]);

          setLoading(false);
        })
        .catch((e) => console.log(e));
    }
  }, [selectedTenant, tenants, updateTrigger, doneFilter]);

  const toggleDoTask = (doTask: IDoTask) => {
    if (!disableDone) {
      setDisableDone(true);
      if (doTask.done == false) {
        audio.currentTime = 0;
        audio.play();
      }
      setDoTasks((prev) => [
        ...prev.map((doTaskMap) => {
          if (doTaskMap == doTask) {
            return { ...doTask, done: !doTask.done };
          } else {
            return doTaskMap;
          }
        }),
      ]);
      updateDoTask({ ...doTask, done: !doTask.done })
        .then((res) => {
          delay(1000).then(() => {
            setDoTasks((prev) => [...prev.filter((i) => i.id !== doTask.id)]);
          });
          setSuccessMessage(translation.savedMessage);
          setDisableDone(false);
        })
        .catch((e) => {
          setErrorMessage(errorMessage(e));
          setDisableDone(false);
        });
    }
  };
  return (
    <Box>
      <Stack spacing={4}>
        <Typography variant="h4">{translation.doTitle}</Typography>
        <Stack>
          {/* <Stack alignItems={"end"}>
                        <Button
                            variant="outlined"
                            startIcon={<HiveOutlined />}
                            onClick={() => setShowFilters(!showFilters)}
                        >
                            {translation.filters}
                        </Button>
                    </Stack>
                    <Collapse in={showFilters}>
                        <Stack
                            direction="row"
                            alignItems={"center"}
                            justifyContent={"flex-end"}
                        >
                            <Switch
                                checked={doneFilter}
                                onChange={(e) => setDoneFilter(!doneFilter)}
                            ></Switch>
                            <Typography> done tasks</Typography>
                        </Stack>
                    </Collapse> */}
          <Stack
            direction="row"
            alignItems={"center"}
            justifyContent={"flex-end"}
          >
            <Switch
              checked={doneFilter}
              onChange={(e) => setDoneFilter(!doneFilter)}
            ></Switch>
            <Typography> done tasks</Typography>
          </Stack>
        </Stack>
        <Stack>
          <Paper sx={{ p: 2, mb: 4 }}>
            <Stack>
              <Stack
                sx={{ width: "100%" }}
                direction={"row"}
                spacing={2}
                alignItems={"center"}
              >
                <SendOutlined color={"disabled"} />
                <InputBase
                  sx={{ width: "100%" }}
                  required
                  id="outlined-required"
                  value={newDoName}
                  onChange={(e) => {
                    setNewDoName(e.target.value);
                  }}
                  onKeyDown={(e) => createDoTask(e)}
                  placeholder={translation.do.newDoLabel}
                />
              </Stack>
            </Stack>
          </Paper>
          <Paper
            sx={{
              p: "48px 24px 24px 24px",
              borderRadius: "0px 0px 0px 8px",
            }}
          >
            <Stack>
              {doTasks.length > 0 ? (
                <TransitionGroup>
                  {doTasks.map((doTask) => (
                    <Collapse key={"list-" + doTask["@id"]}>
                      <Stack
                        direction={"row"}
                        spacing={2}
                        alignItems={"center"}
                        sx={{ minHeight: 100 }}
                      >
                        <Checkbox
                          checked={doTask.done}
                          onClick={(e) => toggleDoTask(doTask)}
                        ></Checkbox>
                        <Stack flexGrow={1}>
                          <Typography variant="h6">{doTask.name}</Typography>
                          <Box
                            sx={{
                              display: "inline-grid",
                            }}
                          >
                            <Typography
                              sx={{
                                color: "text.secondary",
                                minWidth: 0,
                              }}
                              variant="subtitle1"
                              noWrap
                            >
                              {doTask.description}
                            </Typography>
                          </Box>
                        </Stack>
                        <Stack
                          direction="row"
                          sx={{
                            minWidth: 200,
                            maxWidth: 200,
                          }}
                          flexWrap={"wrap"}
                        >
                          {doTask.tags?.map((tag) => (
                            <Chip
                              key={tag["@id"]}
                              label={tag.name}
                              sx={{ m: 1 }}
                            ></Chip>
                          ))}
                        </Stack>
                        <Stack>
                          <Typography variant="caption">
                            {translation.do.dueDate}
                          </Typography>

                          <Typography variant="body2" noWrap>
                            {formattedDate(doTask.dueDate)}
                          </Typography>
                        </Stack>
                        <Box>
                          <IconButton
                            onClick={() => {
                              setDoTaskToEdit(doTask);
                              setShowNewDoTask(true);
                            }}
                          >
                            <EditOutlined />
                          </IconButton>
                        </Box>
                      </Stack>
                    </Collapse>
                  ))}
                </TransitionGroup>
              ) : loading ? (
                <Typography>{translation.loading}</Typography>
              ) : (
                <Typography>{translation.do.noDos}</Typography>
              )}
            </Stack>
          </Paper>
        </Stack>
      </Stack>
      <DoTaskForm
        showNewDoTask={showNewDoTask}
        setShowNewDoTask={setShowNewDoTask}
        doTaskToEdit={doTaskToEdit}
        setDoTaskToEdit={setDoTaskToEdit}
        updateTrigger={updateTrigger}
        setUpdateTrigger={setUpdateTrigger}
      ></DoTaskForm>
    </Box>
  );
};

export default Do;
