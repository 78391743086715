export const importCSV = [
  [
    "id",
    "type",
    "name",
    "sku",
    "parentSKU",
    "hasStock",
    "stockCount",
    "stockLimit",
    "subcategory",
    "attribute",
    "description",
    "slogan",
    "brand",
    "tax",
    "netPrice",
    "grossPrice",
    "priceCurrency",
    "visibility",
    "unitOfMeasurement",
    "weight",
    "length",
    "width",
    "height",
    "isVirtual",
    "slug",
    "pn",
    "barcode",
    "otherInfo",
    "cost",
    "discountPercentage",
    "salePrice",
    "discountStartDate",
    "discountEndDate",
  ],
];
