import React, { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useUserContext } from "components/contexts/UserContext";
//import Loading from "./../components/Loading";
import { routeNames } from "routes";
import { parseJwt } from "helpers";
import { object } from "yup";
import { login, logout } from "services/users";
import { dayCalendarSkeletonClasses } from "@mui/x-date-pickers";
import dayjs from "dayjs";

export default function PrivateRoute({
  children,
}: {
  children: React.ReactNode;
}) {
  const { user, isLoading } = useUserContext();
  const location = useLocation();
  if (isLoading) {
    //return <Loading />;
    return <React.Fragment>Loading...</React.Fragment>;
  }
  if (user) {
    const jwt = parseJwt(user.token);

    if (jwt.isVerified === false && location.pathname !== routeNames.verify) {
      return (
        <Navigate
          to={routeNames.verify}
          replace
          state={{ path: location.pathname }}
        />
      );
    }

    const now = dayjs();
    const exp = dayjs.unix(jwt.exp);
    console.log(now);
    console.log(exp);
    if (exp.isBefore(now)) {
      logout().then(() => (window.location.href = routeNames.home));
    }

    if (!jwt.hasOwnProperty("tenantIds")) {
      logout();
    }

    if (
      jwt.tenantIds.length === 0 &&
      location.pathname !== routeNames.infraWizard
    ) {
      return (
        <Navigate
          to={routeNames.infraWizard}
          replace
          state={{ path: location.pathname }}
        />
      );
    } else {
      return <React.Fragment>{children}</React.Fragment>;
    }
  }
  //redirect if there is no user

  return (
    <Navigate
      to={routeNames.home}
      replace
      state={{ path: location.pathname }}
    />
  );
}
