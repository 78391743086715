import React, { useEffect, useState } from "react";
import { useUserContext } from "components/contexts/UserContext";
import useTranslation from "components/customHooks/translations";
import { getAppsByTenantId, getAppDeploymentStatus } from "services/tenants";

import PapperCollapse from "components/PaperCollapse";
import { CircularProgress } from "@mui/material";

const Application = () => {
    const { selectedTenant, tenants, updateTrigger } = useUserContext();
    const [tenant, setTenant] = useState<any>(null);

    const [appIsLoading, setAppIsLoading] = useState(false);
    const [appDeploymentStatusIsLoading, setAppDeploymentStatusIsLoading] =
        useState(false);
    const [app, setApp] = useState<any>(null);
    const [appDeployed, setAppDeployed] = useState(false);
    const [formError, setFormError] = React.useState("");
    const translation = useTranslation();

    useEffect(() => {
        if (tenants) {
            setTenant(tenants[selectedTenant]);
        }
    }, [selectedTenant, tenants]);

    useEffect(() => {
        setAppIsLoading(true);

        if (tenants) {
            getAppsByTenantId(tenants[selectedTenant].id)
                .then((res) => {
                    setApp(res.data["hydra:member"][0]);
                    if (res.data["hydra:member"][0].status == "deployed") {
                        setAppDeployed(true);
                    }
                    setAppIsLoading(false);
                })
                .catch((e) => console.log(e));
        }
    }, [selectedTenant, tenants, updateTrigger]);

    useEffect(() => {
        setAppDeploymentStatusIsLoading(true);
        if (app !== null) {
            if (app.status !== "deployed") {
                getAppDeploymentStatus(app.id)
                    .then((res) => {
                        if (res.data.done === true) {
                            setAppDeployed(true);
                            setApp({ ...app, status: "deployed" });
                        }
                    })
                    .catch((e) => console.log(e));
                const checkInterval = setInterval(() => {
                    getAppDeploymentStatus(app.id)
                        .then((res) => {
                            if (res.data.done === true) {
                                setAppDeployed(true);
                                setApp({ ...app, status: "deployed" });
                                clearInterval(checkInterval);
                            }
                        })
                        .catch((e) => console.log(e));
                }, 30000);
                return function cleanup() {
                    clearInterval(checkInterval);
                };
            }
        }
    }, [app]);

    return (
        <>
            <PapperCollapse>
                {app != null && (
                    <>
                        <h4>
                            Domain:{" "}
                            <a
                                href={"https://" + app.domain}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {app.domain}
                            </a>
                        </h4>

                        <h4>
                            Status:{" "}
                            {appDeployed ? "Deployed" : <CircularProgress />}
                        </h4>
                    </>
                )}
            </PapperCollapse>
        </>
    );
};

export default Application;
