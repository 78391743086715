import { useEffect, useState } from "react";
import { useUserContext } from "components/contexts/UserContext";
import useTranslation from "components/customHooks/translations";
import PapperCollapse from "components/PaperCollapse";
import PageHeader from "components/PageHeader";
import DnsZone from "components/DnsZone";
import { getDnsZonesByTenant, newDnsZone } from "services/dns";
import { useLoadingContext } from "components/contexts/LoadingContext";
import {
    Box,
    Button,
    Container,
    Drawer,
    Grid,
    Link,
    Paper,
    Stack,
    Typography,
} from "@mui/material";
import { Add } from "@mui/icons-material";
import * as Yup from "yup";
import { formDrawerWidth } from "components/Form/constants";
import { Formik } from "formik";
import { IDnsZone } from "interfaces";
import { useSnackbarContext } from "components/contexts/SnackbarContext";
import FormTextField from "components/Form/FormTextField/FormTextField";
import SwitchField from "components/Form/SwitchField/SwitchField";
import { LoadingButton } from "@mui/lab";

const Infrastructure = () => {
    const { selectedTenant, tenants } = useUserContext();
    const { setLoading } = useLoadingContext();
    const { setErrorMessage, setSuccessMessage } = useSnackbarContext();
    const [updateTrigger, setUpdateTrigger] = useState(false);
    const [openForm, setOpenForm] = useState(false);
    const [dnsZones, setDnsZones] = useState<any>([]);
    const translation = useTranslation();
    const domainSchema = Yup.object().shape({
        domainName: Yup.string()
            .min(2, translation.signUpShortError)
            .max(50, translation.signUpLongError)
            .required(translation.required)
            .matches(
                /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/,
                translation.dnsZone.invalidDomainNameError
            ),
        isManaged: Yup.boolean().required(translation.required),
    });

    const setUpDomain = (values: any, setSubmitting: any) => {
        const dnsZone: IDnsZone = {
            domainName: values.domainName,
            tenant: tenants[selectedTenant]["@id"],
            type: values.isManaged ? "managed" : "customer",
        };
        newDnsZone(dnsZone)
            .then((response) => {
                setSuccessMessage(translation.dnsZone.newDnsZoneSuccess);
                setSubmitting(false);
                setOpenForm(false);
                setUpdateTrigger(!updateTrigger);
            })
            .catch((e: any) => {
                setSubmitting(false);
                setOpenForm(false);
                setErrorMessage(e.response.data.message);
            });
    };

    useEffect(() => {
        setLoading(true);

        if (tenants) {
            getDnsZonesByTenant(tenants[selectedTenant].id)
                .then((res) => {
                    setDnsZones(res.data["hydra:member"]);
                    setLoading(false);
                })
                .catch((e) => console.log(e));
        }
    }, [selectedTenant, tenants, updateTrigger]);

    return (
        <>
            <Stack direction={"row"} justifyContent={"flex-end"}>
                <Box sx={{ mb: 4 }}>
                    <Button
                        variant={"contained"}
                        startIcon={<Add />}
                        onClick={() => setOpenForm(true)}
                    >
                        {translation.dnsZone.newDnsZone}
                    </Button>
                </Box>
            </Stack>
            <Container>
                <Grid container spacing={2}>
                    {dnsZones.map((dnsZone: any, index: number) => {
                        return (
                            <Grid item key={"dns-zone-" + index} md={6}>
                                <Link
                                    href={
                                        "/infrastructure/dnsZone/" + dnsZone.id
                                    }
                                    underline="none"
                                >
                                    <Paper sx={{ p: 2, height: 100 }}>
                                        <Stack spacing={2}>
                                            <Stack
                                                direction="row"
                                                justifyContent={"space-between"}
                                            >
                                                <Typography variant="h5">
                                                    {dnsZone.domainName}
                                                </Typography>
                                                {dnsZone.type == "managed" ? (
                                                    <Typography>
                                                        {
                                                            translation.dnsZone
                                                                .isManagedLabel
                                                        }
                                                    </Typography>
                                                ) : (
                                                    <Typography>
                                                        {
                                                            translation.dnsZone
                                                                .isNotManagedLabel
                                                        }
                                                    </Typography>
                                                )}
                                            </Stack>
                                            <Typography>
                                                {dnsZone.type == "managed"
                                                    ? translation.dnsZone
                                                          .status +
                                                      ": " +
                                                      dnsZone.status
                                                    : ""}
                                            </Typography>
                                        </Stack>
                                    </Paper>
                                </Link>
                            </Grid>
                        );
                    })}
                    {dnsZones.length === 0 && (
                        <Typography>{translation.dnsZone.noDnsZone}</Typography>
                    )}
                </Grid>
            </Container>
            <Drawer
                anchor="right"
                open={openForm}
                onClose={() => {
                    setOpenForm(false);
                }}
                sx={{
                    "& .MuiDrawer-paper": { width: formDrawerWidth },
                }}
            >
                <Formik
                    initialValues={{
                        domainName: "",
                        isManaged: true,
                    }}
                    validationSchema={domainSchema}
                    enableReinitialize
                    onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(true);
                        setUpDomain(values, setSubmitting);
                    }}
                >
                    {({
                        handleSubmit,
                        errors,
                        touched,
                        isValid,
                        isSubmitting,
                        values,
                    }) => (
                        <form noValidate className="" onSubmit={handleSubmit}>
                            <Box sx={{ p: 4 }}>
                                <Typography variant="h5">
                                    {translation.dnsZone.newDnsZone}
                                </Typography>
                                <Stack spacing={2}>
                                    <FormTextField
                                        label={translation.dnsZone.domainName}
                                        name="domainName"
                                    />

                                    <SwitchField
                                        name="isManaged"
                                        label={
                                            translation.dnsZone.isManagedLabel
                                        }
                                    ></SwitchField>
                                    <Stack
                                        direction={"row"}
                                        spacing={2}
                                        justifyContent={"space-between"}
                                    >
                                        <Button
                                            variant={"outlined"}
                                            onClick={() => {
                                                setOpenForm(false);
                                            }}
                                        >
                                            {translation.closeButton}
                                        </Button>
                                        <LoadingButton
                                            variant="contained"
                                            type="submit"
                                            loading={isSubmitting}
                                        >
                                            {translation.dnsZone.newDnsZone}
                                        </LoadingButton>
                                    </Stack>
                                </Stack>
                            </Box>
                        </form>
                    )}
                </Formik>
            </Drawer>
        </>
    );
};

export default Infrastructure;
