import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import { routeNames } from "routes";
import useTranslation from "components/customHooks/translations";
import { Formik } from "formik";
import * as Yup from "yup";
import FormTextField from "components/Form/FormTextField/FormTextField";
import logo from "assets/img/logo_transparent.png";
import { useLocation } from "react-router-dom";
import { useUserContext } from "components/contexts/UserContext";
import { Box, Paper, Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";

const SignIn = () => {
    const translation = useTranslation();
    const navigate = useNavigate();
    const [formError, setFormError] = React.useState("");
    const { state }: any = useLocation();
    const { user, loginUser, isLoading } = useUserContext();

    const SigninSchema = Yup.object().shape({
        email: Yup.string()
            .email(translation.signUpEmailError)
            .required(translation.required),
        password: Yup.string()
            .min(6, translation.signUpShortError)
            .required(translation.required),
    });

    React.useEffect(() => {
        if (user !== null) {
            navigate(routeNames.dashboard);
        }
    }, [isLoading]);

    return (
        <Stack
            justifyContent={"center"}
            alignItems={"center"}
            spacing={4}
            sx={{ height: "100vh" }}
        >
            <img src={logo} alt="src" className="logo-sign-in "></img>
            <Paper sx={{ p: 2 }} elevation={3}>
                <Typography variant="h5">{translation.signInHeader}</Typography>
                <a href={routeNames.passwordReset}>
                    {translation.signInMessage}
                </a>

                <Formik
                    initialValues={{
                        email: "",
                        password: "",
                    }}
                    validationSchema={SigninSchema}
                    onSubmit={(values, { setSubmitting }) => {
                        // same shape as initial values

                        loginUser(values.email, values.password)
                            .then((response: any) => {
                                console.log(response);
                                console.log(state);
                                navigate(state?.path || routeNames.dashboard);

                                setSubmitting(false);
                            })
                            .catch((e: any) => {
                                if (e.response.status === 401) {
                                    setFormError(
                                        translation.signInInvalidCredentials
                                    );
                                }
                                setSubmitting(false);
                            });
                    }}
                >
                    {({
                        handleSubmit,
                        errors,
                        touched,
                        isValid,
                        isSubmitting,
                    }) => (
                        <form
                            noValidate
                            className="sign-in-form"
                            onSubmit={handleSubmit}
                        >
                            <h5 className="error-label">{formError}</h5>
                            <Stack spacing={2}>
                                <FormTextField
                                    label={translation.signUpEmailLabel}
                                    name="email"
                                />

                                <FormTextField
                                    label={translation.signUpPasswordLabel}
                                    name="password"
                                    type="password"
                                />
                                <Box
                                    display={"flex"}
                                    justifyContent={"flex-end"}
                                >
                                    <LoadingButton
                                        type="submit"
                                        loading={isSubmitting}
                                        variant="contained"
                                    >
                                        {translation.signInButton}
                                    </LoadingButton>
                                </Box>
                            </Stack>
                        </form>
                    )}
                </Formik>

                <h3 className="sign-in-already">
                    {translation.signInAlready}
                    <br />
                    <Link to={routeNames.signUp}>
                        {translation.signInAlreadyLink}
                    </Link>
                </h3>
            </Paper>
        </Stack>
    );
};

export default SignIn;
