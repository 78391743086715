import React, { useEffect, useState } from "react";
import { useUserContext } from "components/contexts/UserContext";
import useTranslation from "components/customHooks/translations";
import {
  deleteCategory,
  getParentCategoriesByTenant,
  getProductsByCategory,
  getProductsByTenant,
  updateCategory,
} from "services/products";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Paper,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import LoadingIcon from "components/Feedback/LoadingIcon";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import ProductsMenu from "components/Menu/ProductsMenu";
import {
  Add,
  ExpandMore,
  ExpandLess,
  Delete,
  DeleteOutline,
  EditOutlined,
} from "@mui/icons-material";
import CategoryForm from "./CategoryForm";
import { ICategory, ICategoryHierarchy } from "interfaces";
import { errorMessage } from "helpers";
import { useSnackbarContext } from "components/contexts/SnackbarContext";
import SelectField from "components/Form/SelectField";
import { Formik } from "formik";
import { LoadingButton } from "@mui/lab";
import { getValueFromValueOptions } from "@mui/x-data-grid/components/panel/filterPanel/filterPanelUtils";

const Categories = () => {
  const { tenantsIsLoading, selectedTenant, tenants } = useUserContext();
  const [categories, setCategories] = useState<ICategory[]>([]);
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [parentCategories, setParentCategories] = useState<
    ICategoryHierarchy[]
  >([]);
  const { setSuccessMessage, setErrorMessage } = useSnackbarContext();
  const [categoriesOpen, setCategoriesOpen] = useState<boolean[]>([]);
  const [categoriesLoading, setCategoriesLoading] = useState(true);
  const [showNewCategory, setShowNewCategory] = useState(false);
  const [categoryToEdit, setCategoryToEdit] = useState<ICategory | null>(null);
  const [categoryToDelete, setCategoryToDelete] = useState<ICategory | null>(
    null
  );
  const [isDeleting, setIsDeleting] = useState(false);
  const [categoryToDeleteHasProducts, setCategoryToDeleteHasProducts] =
    useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [categoriesList, setCategoriesList] = useState<ICategoryHierarchy[]>(
    []
  );
  const theme = useTheme();
  const translation = useTranslation();

  useEffect(() => {
    setCategoriesLoading(true);

    if (tenants !== null) {
      getParentCategoriesByTenant(tenants[selectedTenant].id)
        .then((res) => {
          setCategories(res.data["hydra:member"]);
          setCategoriesLoading(false);
        })
        .catch((e) => console.log(e));
    }
  }, [selectedTenant, tenants, updateTrigger]);

  useEffect(() => {
    if (categories !== null) {
      const open = categories.map((_category: ICategory) => {
        return false;
      });
      const newParentCategories: ICategoryHierarchy[] = [];
      const newCategoriesList: ICategoryHierarchy[] = [];
      categories.forEach((category: ICategory) => {
        newParentCategories.push({
          id: category.id,
          name: category.name,
        });
        newCategoriesList.push({
          id: category.id,
          name: category.name,
        });
        if (category.subcategories)
          category.subcategories.forEach((subcategories: ICategory) => {
            newParentCategories.push({
              id: subcategories.id,
              name: subcategories.name,
              parentName: category.name,
            });
            if (subcategories.subcategories)
              subcategories.subcategories.forEach(
                (subsubcategory: ICategory) => {
                  newCategoriesList.push({
                    id: subsubcategory.id,
                    name: subsubcategory.name,
                    parentName: category.name + " • " + subcategories.name,
                  });
                }
              );
          });
      });
      setParentCategories(newParentCategories);
      setCategoriesList(newCategoriesList);
      setCategoriesOpen(open);
    }
  }, [categories]);

  const handleEdit = (category: ICategory) => {
    setCategoryToEdit(category);
    setShowNewCategory(true);
  };

  const handleDeleteCategory = async (newCategory: string) => {
    if (categoryToDelete) {
      setCategoriesLoading(true);
      if (categoryToDeleteHasProducts) {
        updateCategory({
          ...categoryToDelete,
          newCategory: newCategory,
        })
          .then((res) => {
            console.log(res);
            setUpdateTrigger(!updateTrigger);
            setSuccessMessage(translation.deletedMessage);
            setShowDeleteModal(false);
            setCategoryToDelete(null);
            setCategoryToDeleteHasProducts(false);
          })
          .catch((e) => {
            setErrorMessage(errorMessage(e));
            setCategoriesLoading(false);
          });
      } else {
        deleteCategory(categoryToDelete)
          .then(() => {
            setUpdateTrigger(!updateTrigger);
            setSuccessMessage(translation.deletedMessage);
            setShowDeleteModal(false);
            setCategoryToDelete(null);
            setCategoryToDeleteHasProducts(false);
          })
          .catch((e) => {
            setErrorMessage(errorMessage(e));
            setCategoriesLoading(false);
          });
      }
    }
  };

  const handleDelete = async (category: ICategory) => {
    if (category.id) {
      const res = await getProductsByCategory(
        tenants[selectedTenant].id,
        category.id
      );
      if (res.data["hydra:totalItems"] > 0) {
        setCategoryToDeleteHasProducts(true);
      }
      console.log(categoryToDeleteHasProducts);
      setCategoryToDelete(category);
      setShowDeleteModal(true);
    }
  };

  return (
    <>
      <ProductsMenu />
      {tenantsIsLoading ? (
        <LoadingIcon />
      ) : (
        selectedTenant != null && (
          <>
            <Stack direction={"row"} justifyContent={"flex-end"}>
              <Box sx={{ mb: 2 }}>
                <Button
                  variant={"contained"}
                  startIcon={<Add />}
                  onClick={() => setShowNewCategory(!showNewCategory)}
                >
                  {translation.newCategory}
                </Button>
              </Box>
            </Stack>
            <Grid container spacing={2}>
              {categoriesLoading ? (
                <LoadingIcon />
              ) : categories && categories.length > 0 ? (
                categories.map((category: ICategory, index: number) => {
                  return (
                    <Grid key={"category-" + index} md={4}>
                      <Paper>
                        <List>
                          <ListItem>
                            <ListItemText primary={category.name} />
                            <IconButton
                              aria-label="edit"
                              onClick={() => {
                                handleEdit(category);
                              }}
                            >
                              <EditIcon color="primary"></EditIcon>
                            </IconButton>
                            {categories.length > 1 &&
                              category.subcategories.length === 0 && (
                                <IconButton
                                  aria-label="delete"
                                  onClick={() => {
                                    handleDelete(category);
                                  }}
                                >
                                  <Delete color="warning"></Delete>
                                </IconButton>
                              )}

                            {category.subcategories.length > 0 && (
                              <IconButton
                                aria-label="expand"
                                onClick={() => {
                                  const newCategoriesOpen = categoriesOpen.map(
                                    (open: boolean, i: number) => {
                                      return index === i ? !open : open;
                                    }
                                  );

                                  setCategoriesOpen(newCategoriesOpen);
                                }}
                              >
                                {categoriesOpen[index] ? (
                                  <ExpandLess />
                                ) : (
                                  <ExpandMore />
                                )}
                              </IconButton>
                            )}
                          </ListItem>
                          {category.subcategories.length > 0 && (
                            <Collapse
                              in={categoriesOpen[index]}
                              timeout="auto"
                              unmountOnExit
                            >
                              <List
                                sx={{
                                  bgcolor: "background.default",
                                }}
                                disablePadding
                              >
                                {category.subcategories.map(
                                  (subcategory: ICategory) => {
                                    return (
                                      <React.Fragment key={subcategory.id}>
                                        <ListItem
                                          sx={{
                                            pl: 4,
                                          }}
                                        >
                                          <ListItemText
                                            primary={subcategory.name}
                                          />
                                          <IconButton
                                            aria-label="edit"
                                            onClick={() => {
                                              handleEdit(subcategory);
                                            }}
                                          >
                                            <EditIcon color="primary"></EditIcon>
                                          </IconButton>
                                          {subcategory.subcategories.length ===
                                            0 && (
                                            <IconButton
                                              aria-label="delete"
                                              onClick={() => {
                                                handleDelete(subcategory);
                                              }}
                                            >
                                              <DeleteOutline color="warning"></DeleteOutline>
                                            </IconButton>
                                          )}
                                          {subcategory.subcategories.length >
                                            0 && <Box sx={{ ml: 5 }}></Box>}
                                        </ListItem>
                                        {subcategory.subcategories.length >
                                          0 && (
                                          <List
                                            sx={{
                                              bgcolor: "background.paper",
                                            }}
                                            disablePadding
                                          >
                                            {subcategory.subcategories.map(
                                              (subsubcategory: ICategory) => {
                                                return (
                                                  <ListItem
                                                    sx={{
                                                      pl: 6,
                                                      bgcolor: "#00000014",
                                                    }}
                                                    key={subsubcategory.id}
                                                  >
                                                    <Typography> • </Typography>
                                                    <ListItemText
                                                      sx={{ pl: 2 }}
                                                      primary={
                                                        subsubcategory.name
                                                      }
                                                    />

                                                    <IconButton
                                                      aria-label="edit"
                                                      onClick={() => {
                                                        handleEdit(
                                                          subsubcategory
                                                        );
                                                      }}
                                                    >
                                                      <EditIcon color="primary"></EditIcon>
                                                    </IconButton>

                                                    <IconButton
                                                      aria-label="delete"
                                                      onClick={() => {
                                                        handleDelete(
                                                          subsubcategory
                                                        );
                                                      }}
                                                    >
                                                      <DeleteOutline color="warning"></DeleteOutline>
                                                    </IconButton>
                                                  </ListItem>
                                                );
                                              }
                                            )}
                                          </List>
                                        )}
                                      </React.Fragment>
                                    );
                                  }
                                )}
                              </List>
                            </Collapse>
                          )}
                        </List>
                      </Paper>
                    </Grid>
                  );
                })
              ) : (
                <>
                  <p>{translation.noCategoriesAvailable}</p>
                </>
              )}
            </Grid>
          </>
        )
      )}
      <CategoryForm
        showNewCategory={showNewCategory}
        setShowNewCategory={setShowNewCategory}
        parentCategories={parentCategories}
        categoryToEdit={categoryToEdit}
        setCategoryToEdit={setCategoryToEdit}
        updateTrigger={updateTrigger}
        setUpdateTrigger={setUpdateTrigger}
      ></CategoryForm>
      <Dialog
        open={showDeleteModal}
        onClose={() => {
          setCategoryToDelete(null);
          setShowDeleteModal(false);
          setCategoryToDeleteHasProducts(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {categoryToDeleteHasProducts
            ? translation.categories.deleteCategoryWithProductsTitle
            : translation.categories.deleteCategoryTitle}
        </DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {categoryToDeleteHasProducts
              ? translation.categories.deleteCategoryWithProductsDescription
              : translation.categories.deleteCategoryDescription}
          </DialogContentText>
          <Typography>{categoryToDelete?.name}</Typography>
        </DialogContent>
        {categoryToDeleteHasProducts ? (
          <Formik
            initialValues={{
              newCategory: "",
            }}
            enableReinitialize
            onSubmit={(values, actions) => {
              if (values.newCategory === "") {
                actions.setFieldError("newCategory", translation.required);
              }
              handleDeleteCategory(values.newCategory);
              actions.setSubmitting(false);
            }}
          >
            {({ submitForm }) => (
              <DialogContent sx={{ pt: 0 }}>
                <SelectField
                  name="newCategory"
                  label={translation.categories.newCategoryField}
                >
                  {categoriesList
                    ? categoriesList
                        .filter(
                          (category: ICategoryHierarchy) =>
                            category.id !== categoryToDelete?.id
                        )
                        .map((category: ICategoryHierarchy) => {
                          return (
                            <MenuItem
                              value={"/categories/" + category.id}
                              key={"productCategory" + category.id}
                            >
                              <Stack
                                sx={{
                                  minHeight: "50px",
                                }}
                                justifyContent={"center"}
                              >
                                {category.parentName && (
                                  <Typography
                                    sx={{
                                      color: "primary.dark",
                                      fontSize: "10px",
                                      fontWeight: 500,
                                    }}
                                  >
                                    {category.parentName}
                                  </Typography>
                                )}
                                <Typography>{category.name}</Typography>
                              </Stack>
                            </MenuItem>
                          );
                        })
                    : ""}
                </SelectField>
                <DialogActions>
                  <Button
                    variant={"outlined"}
                    onClick={() => {
                      setCategoryToDelete(null);
                      setShowDeleteModal(false);
                      setCategoryToDeleteHasProducts(false);
                    }}
                  >
                    {translation.closeButton}
                  </Button>

                  <LoadingButton
                    type="submit"
                    onClick={submitForm}
                    variant="contained"
                    loading={categoriesLoading}
                  >
                    {translation.deleteButton}
                  </LoadingButton>
                </DialogActions>
              </DialogContent>
            )}
          </Formik>
        ) : (
          <DialogActions>
            <Button
              variant={"outlined"}
              onClick={() => {
                setCategoryToDelete(null);
                setShowDeleteModal(false);
                setCategoryToDeleteHasProducts(false);
              }}
            >
              {translation.closeButton}
            </Button>

            <LoadingButton
              onClick={() => handleDeleteCategory("")}
              variant="contained"
              loading={categoriesLoading}
            >
              {translation.deleteButton}
            </LoadingButton>
          </DialogActions>
        )}
      </Dialog>
    </>
  );
};

export default Categories;
