let en = {
    homeTitle: "Home",
    homeText: "Register now",
    okButton: "Great",
    confirmDelete: "DELETE",
    signOut: "Sign Out",
    myProfile: "My\u00a0Profile",
    myProfileTitle: "My\u00a0Profile",
    myProfilePage: {
        firstName: "First Name",
        lastName: "Last Name",
        email: "Email",
        nickname: "Nickname",
        photo: "Avatar",
    },
    ecommerceMenu: {
        ecommerceSettings: "E-Commerce\u00a0Settings",
        design: "Design",
        customOptions: "Custom Options",
    },
    settings: "Business",
    payments: "Payments",
    paymentsTitle: "Payments",
    noPaymentsAvailable: "No payments have been set up.",
    newPayment: "New Payment",
    editPayment: "Edit Payment",
    deletePayment: "Delete Payment",
    deletePaymentMessage:
        "Are you sure you want to delete the folowing payment method?",
    paymentLabel: "Payment Label",
    paymentInfo: "Info",
    paymentDescription: "Description",
    paymentType: "Type",
    savePayment: "Save Payment",
    save: "Save",
    paymentIban: "IBAN",
    activate: "Activate",
    deactivate: "Deactivate",
    stripeOnboardingButton: "Continue Onboarding",
    applicationTitle: "Application Settings",
    infrastructureTitle: "Infrastructure",
    categoriesTitle: "Categories",
    newCategory: "New Category",
    editCategory: "Edit Category",
    categoryName: "Category Name",
    noCategoriesAvailable: "No categories have been set up.",
    categoryIsVisibleLabel: "Is visible",
    saveCategory: "Save Category",
    categories: {
        deleteCategoryTitle: "Delete Category",
        deleteCategoryDescription:
            "Are you sure you want to delete the following category?",
        deleteCategoryWithProductsTitle: "Delete Category with active products",
        deleteCategoryWithProductsDescription:
            "You are trying to delete a category which has active products!\n Please choose another category to set for the products",
        newCategoryField: "New Category",
    },
    productsTitle: "Products",
    newProductTitle: "New Product",
    editProductTitle: "Edit Product",
    products: {
        noProductsAvailable: "No products.",
        newButton: "New Products",
        copyButton: "Duplicate",
        createNewProductButton: "Create Product",
        productNameField: "Product Name",
        skuField: "SKU",
        pnLabel: "PN",
        barcodeLabel: "Barcode",
        otherInfoLabel: "Other short info",
        brandField: "Brand",
        sloganField: "Slogan",
        categoryField: "Category",
        hasStockLabel: "Use stock?",
        stockCountField: "Stock Count",
        stockLimitField: "Stock Limit",
        stockLimitDescription:
            "You will be notified when the stock reaches below this value.",
        unitOfMeasurementField: "UM",
        priceField: "Price",
        priceCurrencyField: "Currency",
        taxPercentageField: "Tax Percentage",
        setTaxes: "Please set up taxes first!",
        descriptionField: "Description",
        imagesField: "Add images",
        discoutLabel: "Discount",
        discountStartDateField: "Start Date",
        discountEndDateField: "End Date",
        isDiscountPercentageField: "Percentage Value?",
        productDiscountPriceField: "Sale price",
        imagesDescriptionField:
            "Click to upload or drag&drop WEBP, PNG or JPG images. Recommended width: 512 pixels minimum.",
        editImage: "Edit Image",
        setMainImage: "Set image as main",
        imageAltLabel: "Image Alt Text",
        visibilityField: "Visibility",
        isVirtualLabel: "Virtual Product",
        weightLabel: "Weight (g)",
        lengthLabel: "Length (cm)",
        widthLabel: "Width (cm)",
        heightLabel: "Height (cm)",
        costLabel: "Product cost",
        isVariableField: "Is Variable",
        variationOptionalField:
            "Fill this field only if you want specific values for this variation",
        variationNameLabel: "Attribute",
        productCreatedAlert: "Product created.",
        selectCategoryLabel: "Select a category",
        parentCategory: "Select a parent Category",
        editProductButton: "Edit Product",
        productErrorAlert:
            "There was an error. Please try again or contact suppport.",
        productEditedAlert: "Product edited",
        basic: "Basic details",
        basicDescription: "Minimum details to get started",
        description: "Marketing",
        descriptionDescription: "All about the product",
        variations: "Variations",
        variationsDescription: "Set up variable products",
        inventory: "Inventory",
        inventoryDescription: "Keep track of your bussiness",
        productFormSuccess: "Product saved!",
        productFormError: "Error. Try again or contact support.",
    },
    productsExportImportTitle: "Import/Export",
    productsExportImport: {
        importFile: "Import File",
        importDescriptionField: "Click to upload or drag&drop CSV file.",
        import: "Import",
        fileSelected: "File selected",
        uploadSuccess: "File imported with success!",
        productsImported: "products imported!",
        bulkUploadImages: "Bulk upload images",
        uploadImages: "Upload Images",
        uploadingImages: "Uploading images",
        doNotClose: "Please do not close the window!",
        uploaded: " uploaded!",
        closeAndGoToProducts: "Close & go to products",
        done: "Done!",
        downloadExampleCSV: "Download Example CSV",
        exportProducts: "Export Products",
    },
    dnsZoneTitle: "Dns Zone",
    dnsZone: {
        title: "Dns Zone",
        newDnsZone: "New DNS Zone",
        domainName: "Domain Name",
        noDnsZone: "No DNS zones",
        isManagedLabel: "Managed by DROP",
        isNotManagedLabel: "Self managed",
        status: "Status",
        nameServers: "Nameservers",
        checkNameserversButton: "Check Nameservers",
        setUpManaged: "Set Up Domain",
        setUpUnamaged: "Manage my own domain",
        customDomainsTitle: "Custom domains",
        noCustomDomains: "No custom domains set up",
        addNewDomain: "Add new custom domain",
        invalidDomainNameError: "Enter a valid domain name (root)",
        addButton: "Add",
        rootDomainText: "Use @ for root domain.",
        domainNameTextField: "Domain Name",
        saveDomainNames: "Save",
        mailDomainTitle: "Email domain",
        mailNotSet: "Email domain not set",
        setEmailButton: "Set up email",
        deleteDnsZone: "Delete Zone",
        deleteRecord: "Delete Record",
        deletedDnsZone: "Zone deleted",
        newDnsZoneSuccess: "Zone created",
        setUpNameServersFirst: "Please set up and verify nameservers first!",
        deleteZoneMessage: "Are you sure you want to delete the folowing zone?",
        deleteRecordMessage:
            "Are you sure you want to delete the folowing record?",
        newCloudflareCheckSuccess: "Check successfully initiated.",
        records: "Records",
        addRecord: "Add Record",
        editRecord: "Edit Record",
        recordNameLabel: "Name",
        recordTypeLabel: "Type",
        recordValueLabel: "Value",
        recordPriorityLabel: "Priority",
        recordTtlLabel: "TTL",
    },
    businessSettingsHeader: "General Settings",
    brandBookHeader: "Brand Book",
    brandBook: {
        sloganLabel: "Slogan",
        sloganDescription:
            "A Brand statement used for clarity, memorability or marketing",
        shortDescriptionLabel: "Short Description",
        addSocialLinkLabel: "Add Social Media Links",
        shortDescriptionDescription:
            "Provide a concise and informative summary of a website's content and purpose, and to entice users to click through to the site",
        logoLabel: "Logo",
        logoDescriptionLabel:
            "WEBP, PNG, JPG or SVG. Recommended width: 512 pixels minimum.",
        logoSquareLabel: "Square Logo for Social Media",
        logoSquareDescriptionLabel:
            "WEBP, PNG, JPG or SVG. Recommended: 512×512 pixels minimum.",
        coverImageLabel: "Cover/Hero Image",
        coverImageDescriptionLabel:
            "WEBP, PNG or JPG or SVG. Recommended: 1920x384 pixels minimum.",
        primaryColorLabel: "Primary Color",
        primaryColorDescription:
            "This is the dominant color used usually chosen based on the brand's color palette.",
        secondaryColorLabel: "Secondary Color",
        secondaryColorDescription:
            "The secondary color is usually used for accents, such as links, buttons, and other interactive elements.",

        saveBrandBook: "Save",
    },
    infrastructureHeader: "Infrastructure",
    customOptions: "Custom Options",
    salesChannelsTitle: "Online Sales Channel",
    salesChannels: {
        header: "Online Sales Channel",
    },
    policiesTitle: "Policies",
    policies: {
        editPolicy: "Edit Policy",
        newPolicy: "New Policy",
        noPolicies: "No policies",
        copyUrl: "Copy Url",
        copiedUrl: "URL copied!",
        lastUpdated: "last updated on",
        name: "Name",
        slugLabel: "URL slug (optional)",
        deletePolicy: "Delete Policy",
        deletePolicyMessage:
            "Are you sure you want to delete the folowing payment policy?",
        successDelete: "Policy deleted!",
        dynamicData: "Insert Dynamic Data",
        searchData: "Search Data",
        generateFromTemplate: "Generate from template",
        policySaved: "Policy Saved",
        policyUpdated: "Policy Updated",
    },
    shippingTitle: "Shipping",
    shipping: {
        methodsTab: "Methods",
        integrationsTab: "Integrations",
        noCouriers: "No courier integration set up",
        courierFormTitle: "Courier integration",
        nameLabel: "Name",
        courierLabel: "Courier",
        usernameLabel: "Username",
        passwordLabel: "Password",
        clientIdLabel: "Client ID",
        pickupPointLabel: "Pick-Up point",
        finishSetUp: "Finish set up!",
        courierDeleteConfirmMessage:
            "Are you sure you want to delete the following courier?",
        shippingMethodDeleteConfirmMessage:
            "Are you sure you want to delete the following shipping method?",
        newCourierButton: "New Courier",
        newShippingMethodButton: "New Shipping Method",
        noShippingMethods: "No shipping methods",
        shippingMethodFormTitle: "Shipping Method",
        typeLabel: "Type",
        typeCourier: "Courier Integration",
        typeSimple: "Simple",
        descriptionLabel: "Description",
        priceLabel: "Price",
        freeTresholdLabel: "Free shipping treshold",
        freeTresholdDescription:
            "The net value of the cart where the shipping will be free",
        discountsInTresholdLabel: "Include discounts in free treshold",
        discountsInTresholdDescription:
            "If you want to include discounts in the treshold where shiping will be free",
        setUpCouriersFirst: "Please set up a courier integration first!",
        serviceLabel: "Service",
        zonesTab: "Zones",
    },
    taxesTitle: "Taxes",
    taxes: {
        taxIncluedLabel: "Tax included in price",
        setTaxesLabel: "Set taxes",
        labelLabel: "Tax Label (optional)",
        taxesSaved: "Taxes Saved",
        taxPercentageRequired: "The percentage value is required!",
        updateProductsPriceTitle: "Do you want to update products net price?",
        updateProductsPriceDescription:
            "You have products that use this tax. \nDo you want to update the net price with the new tax percentage value?",
        deactivateTaxTitle: "Deactivate tax with active products",
        deactivateTaxDescription:
            "You are trying to deactivate a tax which has active products!\n Please choose another tax to set for the products, and if you want to update the products net price with the new tax difference.",
    },
    currencyTitle: "Currencies",
    currency: {
        defaultCurrencyLabel: "Default Currency",
        enabledCurrenciesLabel: "Enabled Currencies",
        currencySaved: "Currency settings saved",
    },
    signUpButton: "Sign Up",
    signUpTitle: "Sign Up",
    signUpHeader1: "Start droping your work.",
    signUpHeader2: "Sign up now.",
    signUpFooter: "Keep doing what you do best. The boring stuff is on us.",
    signUpAlready: "You have an account?",
    signUpAlreadyLink: "Sign in here",
    signUpFirstNameLabel: "First Name",
    signUpLastNameLabel: "Last Name",
    signUpEmailLabel: "Email",
    signUpPasswordLabel: "Password",
    signUpButtonLabel: "Start your journey",
    signUpShortError: "Too short, please use more than 2 characters!",
    signUpLongError: "Too Long!",
    signUpEmailError: "Invalid email",
    required: "Required",
    signInHeader: "Do your work. Login to your account.",
    signInMessage: "Forgot password? Reset it.",
    signInButton: "Sign in",
    signInAlready: "You don't have an account?",
    signInAlreadyLink: "Sign up",
    signInInvalidCredentials: "User or password is incorrect!",
    verifyTitle: "Verify your email",
    verifyHeader: "Congratulations!",
    verifyHeaderMessage: "Your account was succesfully created.",
    verifyMessage: "Now check your email to confirm your adress.",
    verifyHeaderVerifiedMessage: "Your email was succesfully verified!",
    verifyEmailError:
        "User created but verification email failed. Please contact support.",
    passwordResetTitle: "Reset Password",
    passwordResetHeader: "Reset Password",
    passwordResetMessage:
        "Type your email and we will send you an email with instructions to reset your password.",
    passwordResetRequestError:
        "There was an error sending your reset email. Please try again or contact support.",
    passwordResetRequestButton: "Reset Password",
    passwordResetRequestSuccess:
        "An email has been sent to the address if an accout exists.",
    passwordResetFormMessage: "Type your new password below to change it.",
    passwordResetSuccess: "You password has been succesfully updated.",
    passwordResetError:
        "There was an error reseting your password. Please try again or contact support.",
    resendVerificationEmailButton: "Resend email",
    resendVerificationEmailSuccess: "Email was sent succesfully",
    dashboardTitle: "Dashboard",
    reportsTitle: "Reports",
    marketingTitle: "Marketing",
    helpdeskTitle: "Helpdesk",
    customersTitle: "Customers",
    ordersTitle: "Orders",
    orderTitle: "Order",
    newOrderTitle: "New Order",
    order: {
        paymentMethod: "Payment Method",
        shippingMethodRequired: "Please select a shipping method",
        paymentMethodRequired: "Please select a payment method",
        customerRequired: "Please select a customer",
        shippingAddressRequired: "Please select a shipping address",
        billingAddressRequired: "Please select a billing address",
        customer: "Customer",
        total: "Total",
        shippingAddress: "Shipping Address",
        billingAddress: "Billing Address",
        delivery: "Delivery",
        generateAwb: "Generate AWB",
        products: "Products",
        addProduct: "Add Product",
        addProductDescription:
            "Search below for a product. Type at least 2 characters.\nOnly first 10 results are shown",
        search: "Search...",
        searchProduct: "Search product",
        searchProductHelp: "2+ chars needed, 10 results max",
        setCustomer: "Set Customer",
        setCustomerDescription:
            "Search below for a customer. Type at least 2 characters.\nOnly first 10 results are shown",
        noOrders: "No orders",
        applyDiscount: "Apply Discount",
        discountPercentage: "Discount Percentage",
        discountAmmount: "Ammount",
        discountSalePrice: "Final Price",
        shipping: "Shipping",
        subtotal: "Subtotal",
        totalDiscount: "Total Discount",
        discountAmmountRequired: "Please enter a discount ammount!",
    },
    doTitle: "Do",
    do: {
        newDoLabel: "Write a to-do and hit enter...",
        noDos: "You are all caught up!",
        dueDate: "Due Date",
        newDo: "Create Do",
        editDo: "Edit Do",
        nameLabel: "Name",
        descriptionLabel: "Description",
        dueDateLabel: "Due Date",
        doneLabel: "Done",
        tagsLabel: "Tags",
        deleteConfirmMessage:
            "Are you sure you want to delete the following Do?",
    },
    settingsTitle: "Settings",
    companyInfoTitle: "General\u00a0Settings",
    subscriptionsTitle: "Subscriptions",
    billingTitle: "Billing",
    infraWizardTitle: "Infrastructure wizard",
    infraWizardHeader: "Start droping your work. It's easy.",
    tenant: {
        vatNumber: "VAT No.",
        regNo: "Reg No.",
        otherId: "Other ID No.",
        shareCapital: "Share Capital",
    },
    companyName: "Company short name",
    companyLegalName: "Company legal name",
    domainName: "Domain Name (ex: mydomain.com)",
    receivingEmail: "Default email for receiving (ex: shop@mydomain.com)",
    supportEmail: "Default email for support (ex: help@mydomain.com)",
    sendingEmail: "Default email for sending (ex: no-reply@mydomain.com)",
    addressLine1: "Address line 1",
    addressLine2: "Address line 2",
    city: "City",
    state: "State/County",
    zipCode: "Zip Code",
    country: "Country",
    infraWizardButton: "Start droping your work",
    createNewCompanyHeader: "Create a new company",
    createNewCompanyButton: "Create Company",
    newCompanyCongrulations: "Congratulations!",
    newCompanyCongrulationsMessage:
        "<p>Masters of AI are deploying your services as we write:). Chill out for a few moments and our Digital Assistant will let you know when everything is ready to be used. </p> <p><strong>Kidding.</strong></p> <p>We’re not using AI or Digital Assistants. We like people and the platform just automates some boring things. <br/><strong>Long live humanity!</strong></p>",
    updateCompanyButton: "Update",
    companyInfo: {
        deleteCompany: "Delete Company",
        deletedCompany: "Company deleted",
        deleteCompanyMessage:
            "Are you sure you want to delete the folowing company?",
        deleteCompanyTypeName: "Type the short name of the company below:",
    },
    couponsTitle: "Coupons",
    createCouponsTitle: "Create Coupon",
    editCouponTitle: "Edit Coupon",
    coupons: {
        noCoupons: "No coupons.",
        newCoupon: "New coupon",
        nameLabel: "Name",
        generateName: "Generate random name",
        descriptionLabel: "Description",
        isPercentageLabel: "Fixed or Percent Rate",
        fixedOption: "Fixed amount",
        percentageOption: "Percentage discount",
        amountLabel: "Amount",
        endDateLabel: "Expiry date",
        worksWithDiscountLabel: "Works with other discounts",
        userUsesLimitLabel: "Limit per user",
        userUsesLimitInfo: "Number of uses per user.\nUse 0 for unlimited.",
        usesLimitLabel: "Limit per coupon",
        usesLimitInfo:
            "Number of times the coupon can be used.\nUse 0 for unlimited.",
    },
    integrationsTitle: "Integrations",
    integrations: {
        gtmLabel: "Google Tag Manager",
        pixelLabel: "Facebook Pixel",
        mailchimpLabel: "MailChimp",
    },
    footerCopyright: "@2022 - TBD Company",
    addNew: "Add new",
    closeButton: "Close",
    cancelButton: "Cancel",
    nextButton: "Next",
    unkownError: "Unkown error. Please contact support!",
    loading: "loading..",
    filters: "Filters",
    status: "Status",
    saveAndCloseButton: "Save & Close",
    saveButton: "Save",
    saveAndBackButton: "Save & Go Back",
    saveAndNewButton: "Save & New",
    editButton: "Edit",
    savedMessage: "Saved",
    deletedMessage: "Deleted",
    deleteButton: "Delete",
    possibleDataLossWarning: "Attention! This could result in data loss!",
    yes: "Yes",
    no: "No",
};

export default en;
