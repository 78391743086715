import React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import FormLabel from "@mui/material/FormLabel";
import { Field, useField } from "formik";
import furniture from "assets/img/furniture.webp";

const TemplateField = ({ name, image }: { image?: string; name: string }) => {
    const [field, meta, helper] = useField(name);
    const isValid = !meta.error;
    const isInvalid = meta.touched && !isValid;
    return (
        <>
            <FormControl>
                <RadioGroup
                    row
                    aria-labelledby="demo-form-control-label-placement"
                    name={field.name}
                    value={field.value}
                    defaultValue="top"
                >
                    <FormControlLabel
                        value="ecommerce-1"
                        control={<Radio />}
                        label={
                            <Card sx={{ maxWidth: 345 }}>
                                <CardMedia
                                    component="img"
                                    height="140"
                                    image={furniture}
                                    alt="furniture"
                                />
                                <CardContent>
                                    <Typography
                                        gutterBottom
                                        variant="h5"
                                        component="div"
                                    >
                                        Furniture Template
                                    </Typography>
                                </CardContent>
                            </Card>
                        }
                        labelPlacement="top"
                    />
                </RadioGroup>
            </FormControl>
        </>
    );
};

export default TemplateField;
