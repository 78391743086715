import React, { useEffect, useState } from "react";
import { Box, Typography, Stack, Button, ToggleButton } from "@mui/material";
import { useUserContext } from "components/contexts/UserContext";
import useTranslation from "components/customHooks/translations";
import { getProductsByTenant } from "services/products";
import { useNavigate } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CircularProgress from "@mui/material/CircularProgress";
import EnhancedTableHead, {
  HeadCell,
  Order,
} from "components/Table/EnhancedTableHead";
import { Add, HiveOutlined, PlusOne } from "@mui/icons-material";
import { routeNames } from "routes";
import ProductsMenu from "components/Menu/ProductsMenu";
import LoadingIcon from "components/Feedback/LoadingIcon";
import { useLoadingContext } from "components/contexts/LoadingContext";

interface Data {
  id: number;
  name: string;
  sku: string;
  stock: number;
  price: string;
}

const headCells: HeadCell[] = [
  {
    id: "name",
    numeric: true,
    disablePadding: false,
    label: "Name",
    sorting: false,
  },
  {
    id: "sku",
    numeric: true,
    disablePadding: false,
    label: "SKU",
    sorting: false,
  },
  {
    id: "stock",
    numeric: true,
    disablePadding: false,
    label: "Stock",
    sorting: false,
  },
  {
    id: "price",
    numeric: true,
    disablePadding: false,
    label: "Price",
    sorting: false,
  },
];

const Products = () => {
  const { selectedTenant, tenants } = useUserContext();
  const [products, setProducts] = useState<any>(null);
  const [productsCount, setProductsCount] = useState(0);
  const [pagesNumber, setPagesNumber] = useState(0);
  const [showFilters, setShowFilters] = useState(false);
  const [rows, setRows] = useState<Data[]>([]);
  const translation = useTranslation();

  const { loading, setLoading } = useLoadingContext();
  const navigate = useNavigate();

  const [order, setOrder] = React.useState<Order>("desc");
  const [orderBy, setOrderBy] = React.useState<string>("id");
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (event: React.MouseEvent<unknown>, orderId: number) => {
    navigate("/edit-product/" + orderId);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const productsToData = (products: any) => {
    const data = products.map((product: any) => {
      const price = tenants[selectedTenant].settings.taxIncluded
        ? product.netPrice
        : product.grossPrice;
      return {
        id: product.id,
        name: product.name,
        stock: product.stockCount,
        sku: product.sku,
        price: price.toString() + " " + product.priceCurrency,
      };
    });

    return data;
  };

  useEffect(() => {
    setLoading(true);

    if (tenants !== null) {
      console.log(tenants);
      getProductsByTenant(
        tenants[selectedTenant].id,
        page + 1,
        rowsPerPage,
        orderBy,
        order
      )
        .then((res) => {
          setProducts(res.data["hydra:member"]);
          setRows(productsToData(res.data["hydra:member"]));
          setProductsCount(res.data["hydra:totalItems"]);
          setPagesNumber(Math.ceil(res.data["hydra:totalItems"] / rowsPerPage));
          setLoading(false);
        })
        .catch((e) => console.log(e));
    }
  }, [selectedTenant, tenants, page, rowsPerPage, orderBy, order]);

  useEffect(() => {
    setPage(0);
  }, [selectedTenant]);

  return (
    <>
      <ProductsMenu />
      <Box sx={{ width: "100%" }}>
        <Stack direction={"row"} justifyContent={"flex-end"}>
          <Box sx={{ mb: 2 }}>
            <Button
              variant={"contained"}
              startIcon={<Add />}
              onClick={() => navigate(routeNames.newProducts)}
            >
              {translation.newProductTitle}
            </Button>
          </Box>
        </Stack>
        {rows.length > 0 ? (
          <Paper sx={{ width: "100%", mb: 2 }}>
            <TableContainer>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={"medium"}
              >
                <EnhancedTableHead
                  headCells={headCells}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                />
                <TableBody>
                  {!loading ? (
                    rows.map((row, index) => {
                      return (
                        <TableRow
                          hover
                          onClick={(event) => handleClick(event, row.id)}
                          tabIndex={-1}
                          key={row.id}
                          style={{
                            height: 100,
                            cursor: "pointer",
                          }}
                        >
                          <TableCell>{row.name}</TableCell>
                          <TableCell>{row.sku}</TableCell>
                          <TableCell>{row.stock}</TableCell>
                          <TableCell>{row.price}</TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow
                      style={{
                        height: 100 * rowsPerPage,
                      }}
                    >
                      <TableCell colSpan={6}>
                        <Stack alignItems="center">
                          <CircularProgress />
                        </Stack>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 20, 30]}
              component="div"
              count={productsCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              backIconButtonProps={
                loading
                  ? {
                      disabled: loading,
                    }
                  : undefined
              }
              nextIconButtonProps={
                loading
                  ? {
                      disabled: loading,
                    }
                  : undefined
              }
            />
          </Paper>
        ) : (
          !loading && (
            <Typography>{translation.products.noProductsAvailable}</Typography>
          )
        )}
      </Box>
    </>
  );
};

export default Products;
