import axios from "helpers/axios";
import { backendURL } from "config";
import { ICategory, ITax } from "interfaces";

export async function createNewTenant(tenant: {
    companyName: string;
    companyLegalName: string;
    domain: string;
    receivingEmail: string;
    supportEmail: string;
    sendingEmail: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    state: string;
    country: string;
    users: Array<string>;
}) {
    const URL = backendURL + "/tenants";

    const res = axios
        .post(
            URL,
            JSON.stringify({
                ...tenant,
            })
        )
        .then((res) => res)
        .catch((error) => {
            throw error;
        });
    return res;
}

export async function exchangeCurrency(
    fromCurrency: string,
    toCurrency: string,
    amount: number
) {
    const URL = backendURL + "/exchange";

    const res = axios
        .post(URL, {
            fromC: fromCurrency,
            toC: toCurrency,
            amount: amount,
        })
        .then((res) => res)
        .catch((error) => {
            throw error;
        });
    return res;
}

export async function getCategoriesByTenant(tenantId: string) {
    const URL = backendURL + "/categories?tenant.id=" + tenantId;
    const res = axios
        .get(URL)
        .then((res) => res)
        .catch((error) => {
            throw error;
        });
    return res;
}

export async function getParentCategoriesByTenant(tenantId: string) {
    const URL =
        backendURL +
        "/categories?tenant.id=" +
        tenantId +
        "&exists[parent]=false";
    const res = axios
        .get(URL)
        .then((res) => res)
        .catch((error) => {
            throw error;
        });
    return res;
}

export async function createCategory(
    name: string,
    parentId: string,
    tenantId: string,
    isVisible: boolean
) {
    const URL = backendURL + "/categories";

    const res = axios
        .post(URL, {
            name: name,
            tenant: "/tenants/" + tenantId,
            parent: parentId ? parentId : null,
            isVisible: isVisible,
        })
        .then((res) => res)
        .catch((error) => {
            throw error;
        });
    return res;
}

export async function updateCategory(category: ICategory) {
    const URL = backendURL + "/categories/" + category.id;
    const res = axios
        .patch(URL, category)
        .then((res) => res)
        .catch((error) => {
            throw error;
        });
    return res;
}

export async function deleteCategory(category: ICategory) {
    const URL = backendURL + "/categories/" + category.id;
    const res = axios
        .delete(URL)
        .then((res) => res)
        .catch((error) => {
            throw error;
        });
    return res;
}

export async function getProductsByTax(tax: ITax) {
    const URL = backendURL + "/products?tax.id=" + tax.id;
    const res = axios
        .get(URL)
        .then((res) => res)
        .catch((error) => {
            throw error;
        });
    return res;
}

export async function getProductsByTenant(
    tenantId: string,
    page = 1,
    itemsPerPage = 10,
    orderBy = "id",
    order = "desc"
) {
    const URL =
        backendURL +
        "/products?tenant.id=" +
        tenantId +
        "&page=" +
        page +
        "&itemsPerPage=" +
        itemsPerPage +
        "&order[" +
        orderBy +
        "]=" +
        order;
    const res = axios
        .get(URL)
        .then((res) => res)
        .catch((error) => {
            throw error;
        });
    return res;
}

export async function exportProductsByTenant(tenantId: string) {
    const URL =
        backendURL +
        "/productsExport?tenant.id=" +
        tenantId +
        "&pagination=false";
    const res = axios
        .get(URL)
        .then((res) => res)
        .catch((error) => {
            throw error;
        });
    return res;
}

export async function getProductsByCategory(
    tenantId: string,
    categoryId: number
) {
    const URL =
        backendURL +
        "/products?tenant.id=" +
        tenantId +
        "&subcategory.id=" +
        categoryId;
    const res = axios
        .get(URL)
        .then((res) => res)
        .catch((error) => {
            throw error;
        });
    return res;
}

export async function getProduct(productId: string) {
    const URL = backendURL + "/products/" + productId;
    const res = axios
        .get(URL)
        .then((res) => res)
        .catch((error) => {
            throw error;
        });
    return res;
}

export async function searchProducts(name: string, tenantId: string) {
    const URL =
        backendURL + "/products?tenant.id=" + tenantId + "&name=" + name;
    const res = axios
        .get(URL)
        .then((res) => res)
        .catch((error) => {
            throw error;
        });
    return res;
}

export async function createProduct(product: any) {
    const URL = backendURL + "/products";

    const res = axios
        .post(URL, product)
        .then((res) => res)
        .catch((error) => {
            throw error;
        });
    return res;
}

export async function bulkUploadProducts(formData: FormData) {
    const URL = backendURL + "/bulk_upload";

    const res = axios
        .post(URL, formData)
        .then((res) => res)
        .catch((error) => {
            throw error;
        });
    return res;
}

export async function editProduct(product: any) {
    console.log(product);
    const URL = backendURL + "/products/" + product.id;

    const res = axios
        .put(URL, product)
        .then((res) => res)
        .catch((error) => {
            throw error;
        });
    return res;
}
