import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useTranslation from "components/customHooks/translations";
import { getProduct } from "services/products";
import LoadingIcon from "components/Feedback/LoadingIcon";
import PageHeader from "components/PageHeader";
import ProductsForm from "./ProductsForm";
import { IVariation } from "interfaces";

const CopyProducts = () => {
  const [product, setProduct] = useState<any>(null);
  const [productisLoading, setProductisLoading] = useState(true);
  const translation = useTranslation();
  const { productId } = useParams();

  useEffect(() => {
    if (productId !== undefined) {
      getProduct(productId)
        .then((product) => {
          if (product.data) {
            product.data.id = null;
            product.data.sku = "";
            if (product.data.isVariable) {
              const variations = product.data.variations.map(
                (variation: IVariation) => {
                  variation.sku = "";
                  variation.id = undefined;
                  variation["@id"] = undefined;
                  return {
                    ...variation,
                    sku: "",
                    id: undefined,
                    "@id": undefined,
                  };
                }
              );
              product.data.variations = variations;
            }
            if (product.data.discount) {
              product.data.discount.id = undefined;
              product.data.discount["@id"] = undefined;
            }
            setProduct(product.data);
            setProductisLoading(false);
          }
        })
        .catch((e) => console.log(e));
    }
  }, [productId]);

  return (
    <>
      {productisLoading && product ? (
        <LoadingIcon />
      ) : (
        <>
          <PageHeader>{translation.editProductTitle}</PageHeader>
          <ProductsForm productToEdit={product}></ProductsForm>
        </>
      )}
    </>
  );
};

export default CopyProducts;
