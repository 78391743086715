import React, { useEffect, useState } from "react";
import { useUserContext } from "components/contexts/UserContext";
import useTranslation from "components/customHooks/translations";
import { Formik, FormikHelpers } from "formik";
import { getSettingsByTenant, updateSettings } from "services/tenants";

import PapperCollapse from "components/PaperCollapse";
import { useLoadingContext } from "components/contexts/LoadingContext";
import {
  Autocomplete,
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useSnackbarContext } from "components/contexts/SnackbarContext";
import { ISettings } from "interfaces";
import { LoadingButton } from "@mui/lab";
import { errorMessage } from "helpers";
import currencies from "helpers/currencies";

interface CurrencyFormValues {
  defaultCurrency?: string;
  enabledCurrencies: string[];
}

const GeneralCustomOptions = () => {
  const { selectedTenant, tenants, updateTrigger, setUpdateTrigger } =
    useUserContext();
  const { setLoading } = useLoadingContext();
  const { setSuccessMessage, setErrorMessage } = useSnackbarContext();
  const [settings, setSettings] = useState<ISettings>({
    taxIncluded: true,
    defaultCurrency: "",
    enabledCurrencies: [],
  });
  const [enabledCurrencies, setEnabledCurrencies] = useState([""]);
  const translation = useTranslation();

  useEffect(() => {
    if (settings.enabledCurrencies && settings.enabledCurrencies.length > 0) {
      setEnabledCurrencies(settings.enabledCurrencies);
    } else {
      setEnabledCurrencies(Object.keys(currencies));
    }
  }, [settings]);

  useEffect(() => {
    setLoading(true);
    if (tenants !== null) {
      getSettingsByTenant(tenants[selectedTenant].id)
        .then((res) => {
          if (res.data["hydra:member"].length > 0) {
            setSettings(res.data["hydra:member"][0]);

            setLoading(false);
          }
        })
        .catch((e) => console.log(e));
    }
  }, [selectedTenant, tenants, updateTrigger]);

  const submitForm = async (
    values: CurrencyFormValues,
    actions: FormikHelpers<CurrencyFormValues>
  ) => {
    try {
      await updateSettings({
        ...settings,
        defaultCurrency: values.defaultCurrency,
        enabledCurrencies: values.enabledCurrencies,
      });
      actions.setSubmitting(false);
      setUpdateTrigger(!updateTrigger);
      setSuccessMessage(translation.currency.currencySaved);
    } catch (e) {
      actions.setSubmitting(false);
      setErrorMessage(errorMessage(e));
      return;
    }
  };

  const initialValues: CurrencyFormValues = {
    defaultCurrency: settings.defaultCurrency ? settings.defaultCurrency : "",
    enabledCurrencies: settings.enabledCurrencies
      ? settings.enabledCurrencies
      : [""],
  };

  return (
    <>
      <Paper sx={{ p: 2 }}>
        <Typography variant="h5">{translation.currencyTitle}</Typography>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          onSubmit={(values, actions) => {
            actions.setSubmitting(true);
            submitForm(values, actions);
          }}
        >
          {({
            isSubmitting,
            submitForm,
            values,
            errors,
            touched,
            setFieldValue,
            setFieldTouched,
          }) => (
            <Box sx={{ py: 2, height: "100%" }}>
              <Stack spacing={2}>
                <Box>
                  <FormControl variant="standard" sx={{ minWidth: 220 }}>
                    <InputLabel id={"default-currency-select-standard-label"}>
                      {translation.currency.defaultCurrencyLabel}
                    </InputLabel>
                    <Select
                      onChange={(event) => {
                        const currency = event.target.value;
                        if (!values.enabledCurrencies.includes(currency)) {
                          const newEnabledCurrencies = [
                            ...values.enabledCurrencies,
                          ];
                          newEnabledCurrencies.push(currency);
                          setFieldValue(
                            "enabledCurrencies",
                            newEnabledCurrencies
                          );
                          setEnabledCurrencies(newEnabledCurrencies);
                        }
                        setFieldValue("defaultCurrency", currency);
                      }}
                      name="defaultCurrency"
                      labelId={"default-currency-select-standard-label"}
                      id={"default-currency-select-standard"}
                      label={translation.currency.defaultCurrencyLabel}
                      value={values.defaultCurrency}
                      error={
                        touched["defaultCurrency"] && !!errors.defaultCurrency
                      }
                      MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
                    >
                      {enabledCurrencies.map((currency) => {
                        return (
                          <MenuItem
                            value={currency}
                            key={"default-" + currency}
                          >
                            {currency}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Box>
                <Box sx={{ maxWidth: 400 }}>
                  <Autocomplete
                    id={"enabledCurrencies"}
                    multiple
                    onChange={(
                      _event: React.SyntheticEvent<Element, Event>,
                      selected: string[]
                    ) => {
                      setFieldValue("enabledCurrencies", selected);
                      if (selected.length > 0) {
                        setEnabledCurrencies(selected);
                      } else {
                        setEnabledCurrencies(Object.keys(currencies));
                      }
                      if (values.defaultCurrency)
                        if (!selected.includes(values.defaultCurrency))
                          setFieldValue("defaultCurrency", "");
                    }}
                    onBlur={() => setFieldTouched("enabledCurrencies", true)}
                    defaultValue={
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      values.enabledCurrencies as any
                    }
                    value={values.enabledCurrencies}
                    options={Object.keys(currencies)}
                    renderTags={(value: readonly string[], getTagProps) =>
                      value.map((option: string, index: number) => (
                        // eslint-disable-next-line react/jsx-key
                        <Chip
                          variant="outlined"
                          label={option}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    sx={{ width: "100%" }}
                    renderInput={(params) => (
                      <TextField
                        error={
                          touched.enabledCurrencies &&
                          !!errors.enabledCurrencies
                        }
                        helperText={
                          touched.enabledCurrencies &&
                          !!errors.enabledCurrencies
                            ? errors.enabledCurrencies
                            : ""
                        }
                        variant="standard"
                        {...params}
                        label={translation.currency.enabledCurrenciesLabel}
                      />
                    )}
                  />
                  {/* <AutocompleteTagsField
                    name="enabledCurrencies"
                    label={translation.currency.enabledCurrenciesLabel}
                    children={Object.keys(currencies)}
                  ></AutocompleteTagsField> */}
                </Box>
                <Box display={"flex"} justifyContent="flex-start">
                  <LoadingButton
                    type="submit"
                    onClick={submitForm}
                    variant="contained"
                    loading={isSubmitting}
                  >
                    {translation.save}
                  </LoadingButton>
                </Box>
              </Stack>
            </Box>
          )}
        </Formik>
      </Paper>
    </>
  );
};

export default GeneralCustomOptions;
