import { IProduct, IProductDiscount } from "interfaces";

export function parseJwt(token: string) {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

export function getIdFromUri(uri: string) {
  const strings = uri.split("/");
  return strings[2];
}

export function formattedDate(date: string | null | undefined) {
  if (date !== null && date !== undefined) {
    return new Date(date).toLocaleDateString("ro-RO");
  } else {
    return "No date";
  }
}

export function errorMessage(error: any) {
  if (error.response.status === 500) {
    return "There was an error on our part. Please try again later or contact support!";
  }
  if (error.response.data["hydra:description"] != undefined) {
    return error.response.data["hydra:description"];
  } else if (error.response.data["message"] != undefined) {
    return error.response.data["message"];
  } else {
    return error.response.data;
  }
}

export function timeout(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function generateRandomString(length: number) {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

export function excerpt(str: string | undefined, length = 10) {
  if (!str) {
    return "";
  }
  if (str.length > length) {
    str = str.substring(0, length) + "...";
  }
  return str;
}

export function isDiscountActive(discount?: IProductDiscount | null) {
  if (!discount) {
    return false;
  }
  const startDate = new Date(discount.startDate);
  const today = new Date();
  if (startDate > today) {
    return false;
  }
  if (!discount.endDate) {
    return true;
  }
  const endDate = new Date(discount.endDate);

  if (endDate < today) {
    return false;
  }
  return true;
}

export const isObjectEmpty = (objectName: any) => {
  return Object.keys(objectName).length === 0;
};

export const stringToHash = (string: string) => {
  let hash = 0;

  if (string.length == 0) return hash;

  for (let i = 0; i < string.length; i++) {
    const char = string.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash;
  }

  return hash;
};

export const delay = (time: number) => {
  return new Promise((resolve) => setTimeout(resolve, time));
};
