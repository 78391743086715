import React from "react";
import { useField } from "formik";
import TextField from "@mui/material/TextField";
import { Box, Stack, Typography } from "@mui/material";

import useTranslation from "components/customHooks/translations";
import FormNumberField from "../FormNumberField";
import InfoPopover from "components/InfoPopover";
import { useUserContext } from "components/contexts/UserContext";
import FormTextField from "../FormTextField/FormTextField";

const ArrayVariationsField = ({
  label,
  name,
}: {
  label?: string;
  name: string;
}) => {
  const translation = useTranslation();
  const { tenantsIsLoading, selectedTenant, tenants } = useUserContext();
  const [field, meta, helper] = useField(name);
  const [fieldStock, metaStock, helperStock] = useField("hasStock");

  return (
    <>
      <Stack spacing={2}>
        <Typography>{label}</Typography>
        {field.value.map((value: any, index: number) => {
          return (
            <Stack
              direction={"row"}
              key={"variation-" + index}
              spacing={2}
              flexWrap={"wrap"}
            >
              <TextField
                variant="standard"
                label={translation.products.variationNameLabel}
                value={value.name}
                onChange={(e) => {
                  const oldValues = field.value.map((v: any) => {
                    return { ...v };
                  });
                  oldValues[index].name = e.target.value;
                  if (index === oldValues.length - 1) {
                    oldValues.push({
                      name: "",
                      sku: "",
                      stockCount: "",
                      netPrice: "",
                      grossPrice: "",
                      barcode: "",
                    });
                  }
                  if (e.target.value === "") {
                    oldValues.splice(index, 1);
                  }
                  helper.setValue([...oldValues]);
                }}
              />
              {fieldStock.value && (
                <FormNumberField
                  type="int"
                  label={translation.products.stockCountField}
                  name={"variations[" + index + "].stockCount"}
                />
              )}

              <Stack direction="row" spacing={1} alignItems={"flex-end"}>
                <FormNumberField
                  type="float"
                  label={translation.products.priceField}
                  name={
                    tenants[selectedTenant].settings.taxIncluded
                      ? "variations[" + index + "].netPrice"
                      : "variations[" + index + "].grossPrice"
                  }
                />
                <Box>
                  <InfoPopover
                    infoContent={translation.products.variationOptionalField}
                  />
                </Box>
              </Stack>
              <Stack direction="row" spacing={1} alignItems={"flex-end"}>
                <FormTextField
                  type="float"
                  label={translation.products.skuField}
                  name={"variations[" + index + "].sku"}
                />
                <Box>
                  <InfoPopover
                    infoContent={translation.products.variationOptionalField}
                  />
                </Box>
              </Stack>
              <Stack direction="row" spacing={1} alignItems={"flex-end"}>
                <FormTextField
                  type="float"
                  label={translation.products.barcodeLabel}
                  name={"variations[" + index + "].barcode"}
                />
                <Box>
                  <InfoPopover
                    infoContent={translation.products.variationOptionalField}
                  />
                </Box>
              </Stack>
            </Stack>
          );
        })}
      </Stack>
    </>
  );
};

export default ArrayVariationsField;
