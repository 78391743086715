import React, { useEffect, useState } from "react";
import { useUserContext } from "components/contexts/UserContext";
import * as Yup from "yup";
import useTranslation from "components/customHooks/translations";
import FormTextField from "components/Form/FormTextField/FormTextField";
import SelectField from "components/Form/SelectField";
import { Formik } from "formik";
import {
    Box,
    Button,
    Drawer,
    MenuItem,
    Stack,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from "@mui/material";
import SwitchField from "components/Form/SwitchField/SwitchField";
import { formDrawerWidth } from "components/Form/constants";
import { useSnackbarContext } from "components/contexts/SnackbarContext";
import { IDnsRecord, IDnsZone } from "interfaces";
import { errorMessage } from "helpers";
import { recordTTLs, recordTypes } from "helpers/dnsRecordsData";
import FormNumberField from "components/Form/FormNumberField";
import { deleteDnsRecord, newDnsRecord, updateDnsRecord } from "services/dns";
import { LoadingButton } from "@mui/lab";

const RecordForm = ({
    showNewRecord,
    setShowNewRecord,
    recordToEdit,
    setRecordToEdit,
    updateTrigger,
    setUpdateTrigger,
    setNewRecord,
    domain,
}: {
    showNewRecord: boolean;
    setShowNewRecord: React.Dispatch<React.SetStateAction<boolean>>;
    recordToEdit?: IDnsRecord | null;
    setRecordToEdit?: React.Dispatch<React.SetStateAction<IDnsRecord | null>>;

    updateTrigger: boolean;
    setUpdateTrigger: React.Dispatch<React.SetStateAction<boolean>>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setNewRecord?: any;
    domain: IDnsZone;
}) => {
    const { selectedTenant, tenants } = useUserContext();
    const [open, setOpen] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const { setSuccessMessage, setErrorMessage } = useSnackbarContext();
    console.log(recordToEdit);
    const translation = useTranslation();

    const recordSchema = Yup.object().shape({
        name: Yup.string()
            .min(1, translation.signUpShortError)
            .max(64, translation.signUpLongError)
            .required(translation.required),
        value: Yup.string().required(translation.required),
        type: Yup.string().required(translation.required),
        priority: Yup.number().when("type", {
            is: "MX",
            then: Yup.number().required(translation.required),
        }),
    });

    const handleDeleteRecord = () => {
        if (recordToEdit) {
            setDeleteLoading(true);
            deleteDnsRecord(recordToEdit)
                .then((response) => {
                    setDeleteLoading(false);
                    setOpen(false);
                    setSuccessMessage(translation.deletedMessage);
                    setShowNewRecord(false);
                    if (setRecordToEdit) {
                        setRecordToEdit(null);
                    }
                    setUpdateTrigger(!updateTrigger);
                })
                .catch((e) => {
                    setDeleteLoading(false);
                    setOpen(false);
                    setErrorMessage(errorMessage(e));
                });
        }
    };

    return (
        <>
            <Drawer
                anchor="right"
                open={showNewRecord}
                onClose={() => {
                    setShowNewRecord(false);
                    if (setRecordToEdit) {
                        setRecordToEdit(null);
                    }
                }}
                sx={{
                    "& .MuiDrawer-paper": { width: formDrawerWidth },
                }}
            >
                <Formik
                    initialValues={{
                        type: recordToEdit ? recordToEdit.type : "",
                        name: recordToEdit ? recordToEdit.name : "",
                        value: recordToEdit ? recordToEdit.value : "",
                        priority: recordToEdit?.priority
                            ? recordToEdit.priority
                            : "",
                        ttl: recordToEdit ? recordToEdit.ttl : 1,
                    }}
                    validationSchema={recordSchema}
                    enableReinitialize
                    onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(true);
                        if (recordToEdit) {
                            updateDnsRecord({
                                ...recordToEdit,
                                type: values.type,
                                name: values.name,
                                dnsZone: domain["@id"],
                                ttl: values.ttl ? Number(values.ttl) : 1,
                                priority:
                                    values.priority !== ""
                                        ? Number(values.priority)
                                        : undefined,
                                value: values.value,
                            })
                                .then(() => {
                                    setSubmitting(false);
                                    setShowNewRecord(false);
                                    if (setRecordToEdit) {
                                        setRecordToEdit(null);
                                    }
                                    setSuccessMessage(translation.savedMessage);
                                    setUpdateTrigger(!updateTrigger);
                                })
                                .catch((e) => {
                                    setErrorMessage(errorMessage(e));
                                    setSubmitting(false);
                                });
                        } else {
                            newDnsRecord({
                                type: values.type,
                                name: values.name,
                                dnsZone: domain["@id"],
                                ttl: values.ttl ? Number(values.ttl) : 1,
                                priority:
                                    values.priority !== ""
                                        ? Number(values.priority)
                                        : undefined,
                                serviceType: "custom",
                                value: values.value,
                            })
                                .then((res) => {
                                    setSubmitting(false);
                                    setShowNewRecord(false);
                                    if (setRecordToEdit) {
                                        setRecordToEdit(null);
                                    }
                                    setSuccessMessage(translation.savedMessage);
                                    setUpdateTrigger(!updateTrigger);
                                    if (setNewRecord) {
                                        setNewRecord(res.data["@id"]);
                                    }
                                })
                                .catch((e) => {
                                    setErrorMessage(errorMessage(e));
                                    setSubmitting(false);
                                });
                        }
                    }}
                >
                    {({ handleSubmit, isSubmitting, values }) => (
                        <form noValidate className="" onSubmit={handleSubmit}>
                            <Box sx={{ p: 4 }}>
                                <Stack spacing={3}>
                                    <Typography variant="h5">
                                        {recordToEdit
                                            ? translation.dnsZone.addRecord
                                            : translation.dnsZone.editRecord}
                                    </Typography>
                                    <SelectField
                                        name="type"
                                        label={
                                            translation.dnsZone.recordTypeLabel
                                        }
                                    >
                                        {recordTypes.map(
                                            (recordType: string) => {
                                                return (
                                                    <MenuItem
                                                        value={recordType}
                                                        key={recordType}
                                                    >
                                                        {recordType}
                                                    </MenuItem>
                                                );
                                            }
                                        )}
                                    </SelectField>
                                    <FormTextField
                                        label={
                                            translation.dnsZone.recordNameLabel
                                        }
                                        name="name"
                                    />
                                    <FormTextField
                                        multiline={values.type === "TXT"}
                                        minRows={values.type === "TXT" ? 3 : 1}
                                        label={
                                            translation.dnsZone.recordValueLabel
                                        }
                                        name="value"
                                    />
                                    <SelectField
                                        name="ttl"
                                        label={
                                            translation.dnsZone.recordTtlLabel
                                        }
                                    >
                                        {Object.keys(recordTTLs).map(
                                            (key: any) => {
                                                return (
                                                    <MenuItem
                                                        value={key}
                                                        key={key}
                                                    >
                                                        {
                                                            recordTTLs[
                                                                key as keyof typeof recordTTLs
                                                            ]
                                                        }
                                                    </MenuItem>
                                                );
                                            }
                                        )}
                                    </SelectField>
                                    {values.type === "MX" && (
                                        <FormNumberField
                                            type="int"
                                            label={
                                                translation.dnsZone
                                                    .recordPriorityLabel
                                            }
                                            name="priority"
                                        />
                                    )}
                                    <Stack
                                        direction={"row"}
                                        spacing={2}
                                        justifyContent={"space-between"}
                                    >
                                        <Stack direction="row" spacing={2}>
                                            <Button
                                                variant={"outlined"}
                                                onClick={() => {
                                                    setShowNewRecord(false);
                                                    if (setRecordToEdit) {
                                                        setRecordToEdit(null);
                                                    }
                                                }}
                                            >
                                                {translation.closeButton}
                                            </Button>
                                            {recordToEdit && (
                                                <Box>
                                                    <Button
                                                        variant="outlined"
                                                        color={"error"}
                                                        onClick={() => {
                                                            setOpen(true);
                                                        }}
                                                    >
                                                        {
                                                            translation.deleteButton
                                                        }
                                                    </Button>
                                                </Box>
                                            )}
                                        </Stack>
                                        <LoadingButton
                                            variant="contained"
                                            type="submit"
                                            loading={isSubmitting}
                                        >
                                            {translation.saveButton}
                                        </LoadingButton>
                                    </Stack>
                                </Stack>
                            </Box>
                        </form>
                    )}
                </Formik>
            </Drawer>
            <Dialog onClose={() => setOpen(false)} open={open} fullWidth>
                <DialogTitle>{translation.dnsZone.deleteRecord}</DialogTitle>
                <DialogContent>
                    <Stack spacing={2}>
                        <Typography>
                            {translation.dnsZone.deleteZoneMessage}
                        </Typography>
                        <Typography color={"primary.dark"}>
                            {recordToEdit?.name}
                        </Typography>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={() => setOpen(false)}>
                        {translation.closeButton}
                    </Button>

                    {recordToEdit && (
                        <LoadingButton
                            loading={deleteLoading}
                            variant="contained"
                            onClick={handleDeleteRecord}
                            color="warning"
                        >
                            {translation.deleteButton}
                        </LoadingButton>
                    )}
                </DialogActions>
            </Dialog>
        </>
    );
};

export default RecordForm;
